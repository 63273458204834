import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

export function Stats({ isLoggedIn }) {
  const [stats, setStats] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const t = searchParams.get("t");

  useEffect(() => {
    document.title = "Statistikk - Tromsø Dartklubb";
    //getStats();
  }, []);

  useEffect(() => {
    if (t === "3mnd") {
      console.log("3mnd");
      getStats(t);
    }
    if (t === "6mnd") {
      console.log("6mnd");
      getStats(t);
    }
    if (!t) {
      getStats();
    }
  }, [t]);

  const getStats = async (t) => {
    let isMounted = true;
    try {
      setStats([]);
      const res = t
        ? await fetch(`https://tromsodartklubb.no/api/getStats.php?t=${t}`)
        : await fetch("https://tromsodartklubb.no/api/getStats.php");
      const data = await res.json();

      if (isMounted) {
        setStats(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <h2>Statistikk (Monrad)</h2>
        </div>
        <div className="col-sm-4">
          <nav>
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <Link
                  className={t ? "page-link" : "page-link active"}
                  to={"/stats"}
                >
                  All-time
                </Link>
              </li>
              <li className="page-item">
                <Link
                  className={t === "6mnd" ? "page-link active" : "page-link"}
                  to={"/stats?t=6mnd"}
                >
                  Siste 6 mnd
                </Link>
              </li>
              <li className="page-item">
                <Link
                  className={t === "3mnd" ? "page-link active" : "page-link"}
                  to={"/stats?t=3mnd"}
                >
                  Siste 3 mnd
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {stats.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-dark table-responsive">
            <thead>
              <tr>
                <th scope="col">Navn</th>
                <th scope="col">Vunnet</th>
                <th scope="col">Tapt</th>
                <th scope="col">Spilt</th>
                <th scope="col">%</th>
              </tr>
            </thead>
            <tbody>
              {stats.map((stat, sKey) => {
                return (
                  <tr key={sKey}>
                    <th scope="row">
                      <Link to={stat.player}>{stat.player}</Link>
                    </th>
                    <th scope="row">{stat.numWins}</th>
                    <th scope="row">
                      {parseInt(stat.numGames - stat.numWins)}
                    </th>
                    <th scope="row">{stat.numGames}</th>
                    <th scope="row">
                      {parseInt(
                        parseFloat(stat.numWins / stat.numGames).toFixed(2) *
                          100
                      )}{" "}
                      %
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <span>Laster statistikk...</span>
      )}
    </div>
  );
}

export function StatPlayer({ isLoggedIn }) {
  const params = useParams();
  const [stats, setStats] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const t = searchParams.get("t");

  useEffect(() => {
    document.title = `Spillerstatistikk: ${params.player}  - Tromsø Dartklubb`;
  }, []);

  useEffect(() => {
    if (t === "3mnd") {
      console.log("3mnd");
      getStats(t);
    }
    if (t === "6mnd") {
      console.log("6mnd");
      getStats(t);
    }
    if (!t) {
      getStats();
    }
  }, [t]);

  const getStats = async (t) => {
    let isMounted = true;
    try {
      setStats([]);
      const res = t
        ? await fetch(
            `https://tromsodartklubb.no/api/getStats.php?player=${params.player}&t=${t}`
          )
        : await fetch(
            `https://tromsodartklubb.no/api/getStats.php?player=${params.player}`
          );
      const data = await res.json();

      if (isMounted) {
        setStats(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <h2>Statistikk {params.player}</h2>
        </div>
        <div className="col-sm-4">
          <nav>
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <Link
                  className={t ? "page-link" : "page-link active"}
                  to={`/stats/${params.player}`}
                >
                  All-time
                </Link>
              </li>
              <li className="page-item">
                <Link
                  className={t === "6mnd" ? "page-link active" : "page-link"}
                  to={`/stats/${params.player}?t=6mnd`}
                >
                  Siste 6 mnd
                </Link>
              </li>
              <li className="page-item">
                <Link
                  className={t === "3mnd" ? "page-link active" : "page-link"}
                  to={`/stats/${params.player}?t=3mnd`}
                >
                  Siste 3 mnd
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <hr />

      {Object.keys(stats).length > 0 ? (
        <>
          <h4>Monrad</h4>
          <div className="row">
            <div className="col-sm-4">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Antall seiere
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {stats.monradData[0].numWins}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="text-white rounded-circle shadow">
                        <i className="bi bi-hand-thumbs-up" />
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm d-none">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up" /> 3.48%
                    </span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Antall tap
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {stats.monradData[0].numGames -
                          stats.monradData[0].numWins}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="text-white">
                        <i className="bi bi-hand-thumbs-down" />
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm d-none">
                    <span className="text-success mr-2">
                      <i className="bi bi-arrow-up" /> 3.48%
                    </span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Antall kamper
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {stats.monradData[0].numGames}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="bi bi-123" />
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm d-none">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up" /> 3.48%
                    </span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h4>Sluttspill - Monrad</h4>
          <div className="row">
            <div className="col-sm-3">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Runde 16 vunnet
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {stats.endgameData[0]["16Won"]}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="text-white rounded-circle shadow">
                        <i className="bi bi-hand-thumbs-up" />
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm d-none">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up" /> 3.48%
                    </span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Kvartfinaler vunnet
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {stats.endgameData[0].kvartWon}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="text-white">
                        <i className="bi bi-hand-thumbs-down" />
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm d-none">
                    <span className="text-success mr-2">
                      <i className="bi bi-arrow-up" /> 3.48%
                    </span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Semifinaler vunnet
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {stats.endgameData[0].semiWon}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="bi bi-123" />
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm d-none">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up" /> 3.48%
                    </span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Finaler vunnet
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {stats.endgameData[0].finaleWon}
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="bi bi-123" />
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm d-none">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up" /> 3.48%
                    </span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h4>Turneringsstatistikk</h4>

          <div className="table-responsive">
            <table className="table table-dark">
              <thead>
                <tr>
                  <th scope="col">Turnering</th>
                  <th scope="col">Monrad - Vunnet</th>
                  <th scope="col">Sluttspill - Plassering</th>
                  <th scope="col">Dato</th>
                </tr>
              </thead>
              <tbody>
                {stats.tourData.map((stat, sKey) => {
                  return (
                    <tr key={sKey}>
                      <th scope="row">
                        <Link to={`../tour/${stat.tID}`}>
                          {stat.turneringsnavn}
                        </Link>
                      </th>
                      <th scope="row">{stat.numWon}</th>
                      <th scope="row">{stat.endgamePlacement}</th>
                      <th scope="row">{stat.dato}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <span>Laster data</span>
      )}
    </div>
  );
}
