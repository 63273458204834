import React, { useEffect, useState, Suspense } from "react";

import { useParams, useNavigate, Link } from "react-router-dom";

export default function Admin({ isLoggedIn }) {
  const page = useParams()["page"];
  //const navigate = useNavigate();

  /*
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("../");
    }
    console.log(isLoggedIn);
  }, [isLoggedIn]);
  */

  const PageSelector = ({ page }) => {
    switch (page) {
      case "tour":
        return <TourOverview />;

      case "tourAdm":
        return <TourAdm />;
      default:
        return "Ikke funnet";
    }
  };

  return page ? (
    <div className="container">
      <PageSelector page={page} />
    </div>
  ) : (
    <div className="container">
      <h1>Administrator</h1>
      <hr />
      <div className="row d-flex">
        <div className="col-sm-4">
          <Link to={"tour"}>
            <div className="d-flex flex-column justify-content-center align-items-center border rounded py-4 bg-dark m-2">
              <i className="bi bi-controller" style={{ fontSize: 64 }} />
              <h4>Turneringer</h4>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

function TourOverview({ isLoggedIn }) {
  const [data, setData] = useState([]);
  const [editTour, setEditTour] = useState(56); // ID på tour du ønsker å endre

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await fetch("https://tromsodartklubb.no/api/adm/tours.php");
      const data = await res.json();
      setData(data);
    } catch (err) {
      return;
    }
  };

  const saveData = async (id) => {
    try {
      const curData = data.filter((d) => d.id === id)[0];
      if (curData) {
        const dataToSend = {
          action: "EDIT_TOUR",
          id: curData.id,
          name: curData.name,
          hidden: curData.hidden,
          round: parseInt(curData.round),
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Endre content type avhengig av serverens krav
            // Legg til andre nødvendige headers her
          },
          body: JSON.stringify(dataToSend),
        };

        await fetch(
          "https://tromsodartklubb.no/api/adm/tours.php",
          requestOptions
        );
      } else {
        alert("Noe gikk galt.. Fikk ikke lagret");
      }
    } catch (err) {
      alert("Noe gikk galt.. Fikk ikke lagret\n" + err.message);
    }
  };

  return (
    <>
      <h1>Turneringer</h1>
      <Suspense fallback={"Laster data..."}>
        <div className="table-responsive">
          <table className="table table-dark">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Navn</th>
                <th scope="col">Runde</th>
                <th scope="col">Høyeste utgang</th>
                <th scope="col">Høyeste utgang (person)</th>
                <th scope="col">Raskeste leg</th>
                <th scope="col">Raskeste leg (person)</th>
                <th scope="col">Skjult</th>
                <th scope="col">Opprettet</th>
                <th scope="col">#</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, k) => {
                return (
                  <tr key={k}>
                    {editTour === d.id ? (
                      <>
                        <th scope="row">{d.id}</th>
                        <td>
                          <input
                            type="text"
                            defaultValue={d.name}
                            onChange={(e) =>
                              setData((prev) => {
                                return prev.map((item) => {
                                  if (item.id === d.id) {
                                    return { ...item, name: e.target.value };
                                  } else {
                                    return item;
                                  }
                                });
                              })
                            }
                            style={{ border: 0, width: "80%" }}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            defaultValue={d.round}
                            onChange={(e) =>
                              setData((prev) => {
                                return prev.map((item) => {
                                  if (item.id === d.id) {
                                    return { ...item, round: e.target.value };
                                  } else {
                                    return item;
                                  }
                                });
                              })
                            }
                            style={{ border: 0, width: "80%" }}
                          />
                        </td>
                        <td>{d.highestOut}</td>
                        <td>{d.highestOutPerson}</td>
                        <td>{d.fastestLeg}</td>
                        <td>{d.fastestLegPerson}</td>
                        <td>
                          <input
                            type="text"
                            defaultValue={d.hidden ? "Ja" : "Nei"}
                            onChange={(e) =>
                              setData((prev) => {
                                return prev.map((item) => {
                                  if (item.id === d.id) {
                                    return { ...item, hidden: e.target.value };
                                  } else {
                                    return item;
                                  }
                                });
                              })
                            }
                            style={{ border: 0, width: "80%" }}
                          />
                        </td>
                        <td>{d.created}</td>
                        <td>
                          <span
                            onClick={() => {
                              setEditTour(null);
                              saveData(d.id);
                            }}
                            style={{
                              cursor: "pointer",
                              color: "grey !important",
                            }}
                            className="text"
                          >
                            Lagre
                          </span>
                        </td>
                      </>
                    ) : (
                      <>
                        <th scope="row">{d.id}</th>
                        <td>
                          <Link to={"../admin/tourAdm/" + d.id}>{d.name}</Link>
                        </td>
                        <td>{d.round}</td>
                        <td>{d.highestOut}</td>
                        <td>{d.highestOutPerson}</td>
                        <td>{d.fastestLeg}</td>
                        <td>{d.fastestLegPerson}</td>
                        <td>{d.hidden ? "Ja" : "Nei"}</td>
                        <td>{d.created}</td>
                        <td>
                          <span
                            onClick={() => setEditTour(d.id)}
                            style={{
                              cursor: "pointer",
                              color: "grey !important",
                            }}
                            className="text"
                          >
                            Endre
                          </span>
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Suspense>
    </>
  );
}

function TourAdm({ isLoggedIn }) {
  const params = useParams();
  const [data, setData] = useState([]);
  const [logs, setLogs] = useState([]);
  const [activePage, setActivePage] = useState("overview");

  const [overviewEditMode, setOverviewEditMode] = useState(false);
  const [monradEditMode, setMonradEditMode] = useState(false);
  const [showSQL, setShowSQL] = useState([]);

  useEffect(() => {
    getData();
    getLogs();
    const logInterval = setInterval(() => {
      getLogs();
    }, 5000);

    return () => {
      clearInterval(logInterval);
    };
  }, []);

  const getData = async () => {
    try {
      const res = await fetch(
        `https://tromsodartklubb.no/api/adm/tours.php?id=${params.subpage}`
      );
      const data = await res.json();
      setData(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getLogs = async () => {
    try {
      const res = await fetch(
        `https://tromsodartklubb.no/api/adm/logs.php?id=${params.subpage}`
      );
      const data = await res.json();
      setLogs(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const editOverview = async () => {
    try {
      const dataToSend = {
        action: "EDIT_TOUR",
        id: data.mainData[0].id,
        name: data.mainData[0].name,
        hidden: data.mainData[0].hidden,
        round: parseInt(data.mainData[0].round),
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Endre content type avhengig av serverens krav
          // Legg til andre nødvendige headers her
        },
        body: JSON.stringify(dataToSend),
      };

      await fetch(
        "https://tromsodartklubb.no/api/adm/tours.php",
        requestOptions
      );

      getData();
    } catch (err) {
      alert("Noe gikk galt.. Fikk ikke lagret\n" + err.message);
    }
  };

  const fixPoints = async (player) => {
    try {
      const dataToSend = {
        action: "FIX_POINTS",
        id: params.subpage,
        player: player,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Endre content type avhengig av serverens krav
          // Legg til andre nødvendige headers her
        },
        body: JSON.stringify(dataToSend),
      };

      await fetch(
        "https://tromsodartklubb.no/api/adm/tours.php",
        requestOptions
      );

      getData();
    } catch (err) {
      alert("Noe gikk galt\n err.message");
    }
  };

  const saveRound = async (round) => {
    try {
      for (let i = 0; i < data.monradData.length; i++) {
        let d = data.monradData[i];

        if (parseInt(d.tRound) === parseInt(round)) {
          const gameID = d.gameID;
          const p1 = d.p1;
          const p2 = d.p2;

          const dataToSend = {
            action: "CHANGE_OPPONENT",
            id: parseInt(params.subpage),
            round: round,
            gameID: gameID,
            p1: p1,
            p2: p2,
          };
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json", // Endre content type avhengig av serverens krav
              // Legg til andre nødvendige headers her
            },
            body: JSON.stringify(dataToSend),
          };

          await fetch(
            "https://tromsodartklubb.no/api/adm/tours.php",
            requestOptions
          );
        }
      }

      getData();
      setMonradEditMode(false);
    } catch (err) {
      alert("Noe gikk galt\n" + err.message);
    }
  };

  const deleteRound = async (round) => {
    try {
      const dataToSend = {
        action: "DELETE_ROUND",
        id: parseInt(params.subpage),
        round: round,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Endre content type avhengig av serverens krav
          // Legg til andre nødvendige headers her
        },
        body: JSON.stringify(dataToSend),
      };

      await fetch(
        "https://tromsodartklubb.no/api/adm/tours.php",
        requestOptions
      );

      getData();
    } catch (err) {
      alert("Noe gikk galt\n" + err.message);
    }
  };

  return (
    <>
      <h1>Turneringsadmin</h1>
      <Suspense fallback={"Laster inn data"}>
        <hr />
        <ul className="nav justify-content-center">
          <li className="nav-item">
            <a
              className="nav-link active"
              aria-current="page"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setActivePage("overview");
              }}
            >
              Oversikt
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setActivePage("players");
              }}
            >
              Spillere/poeng
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setActivePage("monrad");
              }}
            >
              Monrad
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setActivePage("endgame");
              }}
            >
              Sluttspill
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setActivePage("logs");
              }}
            >
              Logs
            </a>
          </li>
        </ul>
        <hr />
        {Object.keys(data).length > 0 && (
          <>
            <div
              id="overview"
              style={
                activePage !== "overview"
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <div className="d-flex justify-content-between">
                <div className="p-2">
                  <h4>
                    Oversikt for: <b>{data.mainData[0].name}</b>
                  </h4>
                </div>
                <div className="p-2">
                  <button
                    className={
                      overviewEditMode
                        ? "btn btn-sm btn-success"
                        : "btn btn-sm btn-danger"
                    }
                    onClick={() =>
                      setOverviewEditMode((prev) => {
                        if (prev) {
                          editOverview();
                        }
                        return !prev;
                      })
                    }
                  >
                    {overviewEditMode ? "Lagre" : "Endre"}
                  </button>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-md-2 g-4">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">
                        {overviewEditMode ? (
                          <input
                            type="text"
                            defaultValue={data.mainData[0].name}
                            onChange={(e) =>
                              setData((prev) => ({
                                ...prev,
                                mainData: [
                                  { ...prev.mainData[0], name: e.target.value },
                                ],
                              }))
                            }
                          />
                        ) : (
                          data.mainData[0].name
                        )}
                      </h4>
                      <p className="card-text">Turneringsnavn</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">
                        {overviewEditMode ? (
                          <input
                            type="text"
                            defaultValue={data.mainData[0].round}
                            onChange={(e) => {
                              setData((prev) => ({
                                ...prev,
                                mainData: [
                                  {
                                    ...prev.mainData[0],
                                    round: e.target.value,
                                  },
                                ],
                              }));
                            }}
                          />
                        ) : (
                          data.mainData[0].round
                        )}
                      </h4>
                      <p className="card-text">Runde</p>
                    </div>
                  </div>
                </div>
              </div>

              <button className="btn btn-sm">Endre</button>
            </div>

            <div
              id="players"
              style={
                activePage !== "players"
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <h4>
                Spillere for: <b>{data.mainData[0].name}</b>
              </h4>
              <div className="row row-cols-1 row-cols-md-2 g-4">
                {data.playerData.map((pInfo, pKey) => {
                  return (
                    <div className="col" key={pKey}>
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">{pInfo.player}</h4>
                          <p className="card-text">
                            Poeng i databasen: {pInfo.points} <br />
                            Poeng i matches: {pInfo.numWins} <br /> <br />
                            Runde i databasen: {pInfo.round}
                          </p>
                          {pInfo.points !== pInfo.numWins && (
                            <button
                              onClick={() => fixPoints(pInfo.player)}
                              className="btn btn-sm btn-primary"
                            >
                              Fiks poeng
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              id="monrad"
              style={
                activePage !== "monrad"
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <h4>
                Monrad for: <b>{data.mainData[0].name}</b>
              </h4>
              <button
                className={
                  monradEditMode
                    ? "btn btn-sm btn-success"
                    : "btn btn-sm btn-danger"
                }
                style={{ marginBottom: 15 }}
                onClick={() => setMonradEditMode((prev) => !prev)}
              >
                {monradEditMode ? "Avslutt endre-modus" : "Endre modus"}
              </button>

              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Runde 1
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Spiller 1</th>
                              <th scope="col">Spiller 2</th>
                              <th scope="col">Resultat</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.monradData.map((game, gameKey) => {
                              if (game.tRound === "1") {
                                return (
                                  <tr key={gameKey}>
                                    <th scope="row">{game.gameID}</th>
                                    {monradEditMode ? (
                                      <>
                                        <td>
                                          <input
                                            type="text"
                                            defaultValue={game.p1}
                                            onChange={(e) =>
                                              setData((prevData) => {
                                                return {
                                                  ...prevData,
                                                  monradData:
                                                    prevData.monradData.map(
                                                      (g) => {
                                                        if (
                                                          g.gameID ===
                                                          game.gameID
                                                        ) {
                                                          return {
                                                            ...g,
                                                            p1: e.target.value,
                                                          };
                                                        } else {
                                                          return g;
                                                        }
                                                      }
                                                    ),
                                                };
                                              })
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            defaultValue={game.p2}
                                            onChange={(e) =>
                                              setData((prevData) => {
                                                return {
                                                  ...prevData,
                                                  monradData:
                                                    prevData.monradData.map(
                                                      (g) => {
                                                        if (
                                                          g.gameID ===
                                                          game.gameID
                                                        ) {
                                                          return {
                                                            ...g,
                                                            p2: e.target.value,
                                                          };
                                                        } else {
                                                          return g;
                                                        }
                                                      }
                                                    ),
                                                };
                                              })
                                            }
                                          />
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td>{game.p1}</td>
                                        <td>{game.p2}</td>
                                      </>
                                    )}
                                    <td>
                                      {game.resultat1} - {game.resultat2}
                                    </td>
                                  </tr>
                                );
                              }
                              return null;
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="p-2">
                          <button
                            className="btn bt-sm btn-danger"
                            onClick={() => deleteRound(1)}
                          >
                            Slett runde 1
                          </button>
                        </div>
                        <div className="p-2">
                          <button
                            className="btn bt-sm btn-success"
                            onClick={() => saveRound(1)}
                          >
                            Lagre runde 1
                          </button>
                        </div>
                      </div>
                      <br />
                      <span className="text-muted">
                        Hvis alle kampene ikke er generert kan man slette
                        runden, deretter korrigere poeng i "Spillere/poeng", og
                        deretter sette runden 1 tilbake på "Oversikt"
                      </span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Runde 2
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Spiller 1</th>
                              <th scope="col">Spiller 2</th>
                              <th scope="col">Resultat</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.monradData.map((game, gameKey) => {
                              if (game.tRound === "2") {
                                return (
                                  <tr key={gameKey}>
                                    <th scope="row">{game.gameID}</th>
                                    {monradEditMode ? (
                                      <>
                                        <td>
                                          <input
                                            type="text"
                                            defaultValue={game.p1}
                                            onChange={(e) =>
                                              setData((prevData) => {
                                                return {
                                                  ...prevData,
                                                  monradData:
                                                    prevData.monradData.map(
                                                      (g) => {
                                                        if (
                                                          g.gameID ===
                                                          game.gameID
                                                        ) {
                                                          return {
                                                            ...g,
                                                            p1: e.target.value,
                                                          };
                                                        } else {
                                                          return g;
                                                        }
                                                      }
                                                    ),
                                                };
                                              })
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            defaultValue={game.p2}
                                            onChange={(e) =>
                                              setData((prevData) => {
                                                return {
                                                  ...prevData,
                                                  monradData:
                                                    prevData.monradData.map(
                                                      (g) => {
                                                        if (
                                                          g.gameID ===
                                                          game.gameID
                                                        ) {
                                                          return {
                                                            ...g,
                                                            p2: e.target.value,
                                                          };
                                                        } else {
                                                          return g;
                                                        }
                                                      }
                                                    ),
                                                };
                                              })
                                            }
                                          />
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td>{game.p1}</td>
                                        <td>{game.p2}</td>
                                      </>
                                    )}
                                    <td>
                                      {game.resultat1} - {game.resultat2}
                                    </td>
                                  </tr>
                                );
                              }
                              return null;
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="p-2">
                          <button
                            className="btn bt-sm btn-danger"
                            onClick={() => deleteRound(2)}
                          >
                            Slett runde 2
                          </button>
                        </div>
                        <div className="p-2">
                          <button
                            className="btn bt-sm btn-success"
                            onClick={() => saveRound(2)}
                          >
                            Lagre runde 2
                          </button>
                        </div>
                      </div>
                      <br />
                      <span className="text-muted">
                        Hvis alle kampene ikke er generert kan man slette
                        runden, deretter korrigere poeng i "Spillere/poeng", og
                        deretter sette runden 1 tilbake på "Oversikt"
                      </span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Runde 3
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Spiller 1</th>
                              <th scope="col">Spiller 2</th>
                              <th scope="col">Resultat</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.monradData.map((game, gameKey) => {
                              if (game.tRound === "3") {
                                return (
                                  <tr key={gameKey}>
                                    <th scope="row">{game.gameID}</th>
                                    {monradEditMode ? (
                                      <>
                                        <td>
                                          <input
                                            type="text"
                                            defaultValue={game.p1}
                                            onChange={(e) =>
                                              setData((prevData) => {
                                                return {
                                                  ...prevData,
                                                  monradData:
                                                    prevData.monradData.map(
                                                      (g) => {
                                                        if (
                                                          g.gameID ===
                                                          game.gameID
                                                        ) {
                                                          return {
                                                            ...g,
                                                            p1: e.target.value,
                                                          };
                                                        } else {
                                                          return g;
                                                        }
                                                      }
                                                    ),
                                                };
                                              })
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            defaultValue={game.p2}
                                            onChange={(e) =>
                                              setData((prevData) => {
                                                return {
                                                  ...prevData,
                                                  monradData:
                                                    prevData.monradData.map(
                                                      (g) => {
                                                        if (
                                                          g.gameID ===
                                                          game.gameID
                                                        ) {
                                                          return {
                                                            ...g,
                                                            p2: e.target.value,
                                                          };
                                                        } else {
                                                          return g;
                                                        }
                                                      }
                                                    ),
                                                };
                                              })
                                            }
                                          />
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td>{game.p1}</td>
                                        <td>{game.p2}</td>
                                      </>
                                    )}
                                    <td>
                                      {game.resultat1} - {game.resultat2}
                                    </td>
                                  </tr>
                                );
                              }
                              return null;
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="p-2">
                          <button
                            className="btn bt-sm btn-danger"
                            onClick={() => deleteRound(3)}
                          >
                            Slett runde 3
                          </button>
                        </div>
                        <div className="p-2">
                          <button
                            className="btn bt-sm btn-success"
                            onClick={() => saveRound(3)}
                          >
                            Lagre runde 3
                          </button>
                        </div>
                      </div>
                      <br />
                      <span className="text-muted">
                        Hvis alle kampene ikke er generert kan man slette
                        runden, deretter korrigere poeng i "Spillere/poeng", og
                        deretter sette runden 1 tilbake på "Oversikt"
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <b>
                Obs! Hvis man endrer på WO, eller andre vitale endringer bør man
                sjekke over spillere/poeng og korrigere. Spilte kamper bør ikke
                endres
              </b>
            </div>

            <div
              id="logs"
              style={
                activePage !== "logs"
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <h4>
                Log for: <b>{data.mainData[0].name}</b>
              </h4>
              <div className="row table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Tittel</th>
                      <th scope="col">Hendelse</th>
                      <th scope="col">Runde</th>
                      <th scope="col">Tidspunkt</th>
                      <th scope="col">SQL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.length > 0 && (
                      <>
                        {logs.map((log, logKey) => {
                          return (
                            <tr key={logKey}>
                              <th scope="row">{logKey + 1}</th>
                              <td>{log.title}</td>
                              <td>{log.summary}</td>
                              <td>{log.tRound}</td>
                              <td>{log.created}</td>
                              <td>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-filetype-sql"
                                  viewBox="0 0 16 16"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setShowSQL(log.query)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM0 14.841a1.13 1.13 0 0 0 .401.823q.194.162.478.252c.284.09.411.091.665.091q.507 0 .858-.158.355-.159.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.187-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.776.15-.337.149-.528.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.325q0 .165-.085.29A.56.56 0 0 1 2 15.31q-.167.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zm6.878 1.489-.507-.739q.264-.243.401-.6.138-.358.138-.806v-.501q0-.556-.208-.967a1.5 1.5 0 0 0-.589-.636q-.383-.225-.917-.225-.527 0-.914.225-.384.223-.592.636a2.14 2.14 0 0 0-.205.967v.5q0 .554.205.965.208.41.592.636a1.8 1.8 0 0 0 .914.222 1.8 1.8 0 0 0 .6-.1l.294.422h.788ZM4.262 14.2v-.522q0-.369.114-.63a.9.9 0 0 1 .325-.398.9.9 0 0 1 .495-.138q.288 0 .495.138a.9.9 0 0 1 .325.398q.115.261.115.63v.522q0 .246-.053.445-.053.196-.155.34l-.106-.14-.105-.147h-.733l.451.65a.6.6 0 0 1-.251.047.87.87 0 0 1-.487-.147.9.9 0 0 1-.32-.404 1.7 1.7 0 0 1-.11-.644m3.986 1.057h1.696v.674H7.457v-3.999h.79z"
                                  />
                                </svg>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      SQL-Query
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Query</th>
                          </tr>
                        </thead>
                        <tbody>
                          {showSQL.length > 0 && (
                            <>
                              {showSQL.split(";").map((sql, key) => (
                                <tr key={key}>
                                  <td>{sql.replaceAll("\\", "")}</td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Lukk
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Suspense>
    </>
  );
}
