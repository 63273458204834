import { Route, Routes } from "react-router-dom";

import Container from "./hooks/Container";
import LoggInn from "./pages/LoggInn";
import Admin from "./pages/Admin";

import Home from "./pages/Home";
import { News, NewsArticle } from "./pages/News";
import Tournament from "./pages/Tournament";
import Gallery from "./pages/Gallery";
import { StatPlayer, Stats } from "./pages/Stats";

import { useEffect, useState } from "react";

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoginAndSetInterval = async () => {
      try {
        // Fetch login status
        await checkLoginStatus();

        // Set up an interval to check login status every 15 seconds
        const interval = setInterval(async () => {
          await checkLoginStatus();
        }, 15000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(interval);
      } catch (err) {
        console.error("Error checking login status:", err);
      }
    };

    // Check if the environment is in development
    if (process.env.NODE_ENV === "development") {
      setLoggedIn(true);
    } else {
      // Call the function that checks login status and sets up the interval
      checkLoginAndSetInterval();
    }
  }, []);

  const checkLoginStatus = async () => {
    try {
      const res = await fetch(
        "https://tromsodartklubb.no/api/security/checkLogin.php"
      );
      const data = await res.json();
      setLoggedIn(!!data); // Set isLoggedIn to true if data is truthy, otherwise false
    } catch (err) {
      console.error("Error checking login status:", err);
      setLoggedIn(false);
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Container isLoggedIn={isLoggedIn}>
            <Home isLoggedIn={isLoggedIn} />
          </Container>
        }
      />
      <Route
        path="/logginn"
        element={
          <Container isLoggedIn={isLoggedIn}>
            <LoggInn
              isLoggedIn={isLoggedIn}
              checkLoginStatus={checkLoginStatus}
            />
          </Container>
        }
      />
      {isLoggedIn && (
        <Route
          path="/admin/:page?/:subpage?"
          element={
            <Container isLoggedIn={isLoggedIn}>
              <Admin isLoggedIn={isLoggedIn} />
            </Container>
          }
        />
      )}
      <Route
        path="/nyheter"
        element={
          <Container isLoggedIn={isLoggedIn}>
            <News isLoggedIn={isLoggedIn} />
          </Container>
        }
      />
      <Route
        path="/nyheter/:id"
        element={
          <Container isLoggedIn={isLoggedIn}>
            <NewsArticle isLoggedIn={isLoggedIn} />
          </Container>
        }
      />
      <Route
        path={"/tour"}
        element={
          <Container isLoggedIn={isLoggedIn}>
            <Tournament isLoggedIn={isLoggedIn} />
          </Container>
        }
      />
      <Route
        path="/tour/:id"
        element={
          <Container isLoggedIn={isLoggedIn}>
            <Tournament isLoggedIn={isLoggedIn} />
          </Container>
        }
      />
      <Route
        path="/stats"
        element={
          <Container isLoggedIn={isLoggedIn}>
            <Stats isLoggedIn={isLoggedIn} />
          </Container>
        }
      />
      <Route
        path="/stats/:player"
        element={
          <Container isLoggedIn={isLoggedIn}>
            <StatPlayer isLoggedIn={isLoggedIn} />
          </Container>
        }
      />
      <Route
        path="/galleri"
        element={
          <Container isLoggedIn={isLoggedIn}>
            <Gallery isLoggedIn={isLoggedIn} />
          </Container>
        }
      />
      <Route
        path="/galleri/:id"
        element={
          <Container isLoggedIn={isLoggedIn}>
            <Gallery isLoggedIn={isLoggedIn} />
          </Container>
        }
      />
    </Routes>
  );
}

export default App;
