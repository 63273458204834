import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function LoggInn({ isLoggedIn, checkLoginStatus }) {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const passwordInputRef = useRef(null);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  const login = async () => {
    try {
      const dataToSend = {
        user: username,
        password: password,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Endre content type avhengig av serverens krav
          // Legg til andre nødvendige headers her
        },
        body: JSON.stringify(dataToSend),
      };

      const res = await fetch(
        "https://tromsodartklubb.no/api/security/login.php",
        requestOptions
      );
      const data = await res.json();

      if (data === true) {
        console.log("Logged in!");
        checkLoginStatus();
        navigate("/");
      } else {
        alert("Feil brukernavn/passord");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkLogin = async () => {
    try {
      const res = await fetch(
        "https://tromsodartklubb.no/api/security/checkLogin.php"
      );
      const data = await res.json();
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <div className="container">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="tdkusername">Brukernavn</label>
          <input
            onChange={(e) => setUsername(e.target.value)}
            type="username"
            className="form-control"
            id="tdkusername"
            aria-describedby="emailHelp"
            placeholder="Brukernavn"
            onSubmit={() => passwordInputRef.current.focus()}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Passord</label>
          <input
            ref={passwordInputRef}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Passord"
            onSubmit={() => login()}
          />
        </div>
        <br />
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => login()}
        >
          Logg inn
        </button>
      </form>
      <br />
      <br />
    </div>
  );
}
