import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function Home() {
  const [tourInfo, setTourInfo] = useState([]);
  const [images, setImages] = useState([]);

  const videoRef = useRef(null);

  useEffect(() => {
    const handlePlay = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    document.addEventListener("DOMContentLoaded", handlePlay);

    return () => {
      document.removeEventListener("DOMContentLoaded", handlePlay);
    };
  }, []);

  useEffect(() => {
    document.title = "Tromsø Dartklubb";

    //const main = document.getElementById("main");
    //main.style.backgroundColor = "transparent"; // Empty string to remove background color

    // Lightbox
    //const lbox = document.getElementById("lightbox");
    //lbox.style.display = "none";

    // Breadcrumbs
    const bread = document.getElementsByClassName("breadcrumbs");
    bread[0].style.display = "none";

    getTours();
    getImages();

    return () => {
      //main.style.backgroundColor = "#192a33";
      //lbox.style.display = "block";
      bread[0].style.display = "block";
    };
  }, []);

  const getTours = async () => {
    try {
      const res = await fetch(
        "https://tromsodartklubb.no/api/getTournaments.php"
      );
      const data = await res.json();
      setTourInfo(data);
    } catch (err) {
      setTourInfo(null);
    }
  };

  const getImages = async () => {
    function shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    }

    try {
      let bilderBulker = [];
      let bilder = [];

      const res = await fetch(
        "https://tromsodartklubb.no/api/getGalleries.php"
      );
      const data = await res.json();
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].bilder.length; j++) {
          bilder.push(data[i].id + "/" + data[i].bilder[j]);
        }
      }

      // Del opp bildene i bulker med 4 bilder per array
      for (let i = 0; i < bilder.length; i += 4) {
        bilderBulker.push(bilder.slice(i, i + 4));
      }

      setImages(shuffle(bilderBulker));
    } catch (err) {
      setImages(null);
    }
  };

  const formatDate = (created) => {
    const date = new Date(created);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year}`;
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundImage: 'url("arrow-index.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div
          className="container py-4"
          style={{ backgroundColor: "transparent", minHeight: "30vh" }}
        >
          <div className="row gx-2">
            <div
              className="col-sm-4 rounded p-5"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <h2 style={{ letterSpacing: 1.5 }}>TROMSØ DARTKLUBB</h2>
              <hr />
              <p>Klubbkveld hver torsdag på Tromsø Bybowling</p>

              <p>
                <i className="bi bi-facebook" /> Besøk vår{" "}
                <a
                  href="https://www.facebook.com/groups/171037096247145"
                  target="_blank"
                  rel="no-referrer"
                >
                  Facebook-gruppe
                </a>
              </p>
            </div>
            <div className="col-sm-8 rounded p-2">
              <video
                src="allsang.mp4"
                ref={videoRef}
                playsInline
                controls
                loop
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 5,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container py-2">
        <div className="row">
          {tourInfo.slice(0, 3).map((tour, k) => {
            return (
              <div key={k} className="col-sm-4">
                <Link to={`tour/${tour.id}`}>
                  <div
                    className="d-flex flex-column  justify-content-end m-2 p-2 shadow-lg rounded"
                    style={{
                      backgroundImage:
                        'url("https://upload.wikimedia.org/wikipedia/commons/f/fb/Darts_in_a_dartboard.jpg")',
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      minHeight: "320px",
                    }}
                  >
                    <h5
                      style={{
                        color: "#192a33",
                        fontWeight: "600",
                        letterSpacing: 3,
                        backgroundColor: "rgba(255,255,255,0.5)",
                        width: "100%",
                        padding: 5,
                      }}
                    >
                      {tour.name}
                    </h5>
                    <div
                      className="row m-0"
                      style={{
                        color: "#FAFAFA",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <div className="col-6">
                        <i
                          className="bi bi-calendar3"
                          style={{ color: "#3498db" }}
                        />{" "}
                        {formatDate(tour.created)}
                      </div>
                      <div className="col-6">
                        <span style={{ float: "right" }}>
                          <i
                            className="bi bi-trophy"
                            style={{ color: "#3498db" }}
                          />{" "}
                          {tour.winner}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className="container py-4 overflow-hidden "
        style={{
          //backgroundColor: "rgba(0, 0, 0, 0.2)",
          backgroundColor: "rgb(25,42,51)",
          background:
            "linear-gradient(90deg, rgba(25,42,51,1) 0%, rgba(25, 50, 65, 0.5) 50%, rgba(25,42,51,1) 100%)",
          width: "85%",
        }}
      >
        <div className="carousel slide" data-bs-ride="carousel">
          <>
            {images.map((img, k) => {
              return (
                <div key={k} className="carousiel-inner">
                  <div
                    className={
                      k === 1 ? "carousel-item active" : "carousel-item"
                    }
                  >
                    <div className="row">
                      {img.map((i, iKey) => {
                        return (
                          <div className="col-sm-3" key={iKey}>
                            <div
                              className="p-3"
                              style={{
                                zIndex: 2,
                                position: "relative",
                              }}
                            >
                              <center>
                                <a
                                  href={`https://tromsodartklubb.no/images/${i}`}
                                  data-lightbox="roadtrip"
                                >
                                  <img
                                    src={`https://tromsodartklubb.no/images/${i}`}
                                    height={300}
                                    className="rounded"
                                    width={"100%"}
                                    alt="..."
                                    loading="lazy"
                                  />
                                </a>
                              </center>
                            </div>
                            <div
                              className="p-3 d-none"
                              style={{
                                backgroundImage: `url(https://tromsodartklubb.no/images/${i})`,
                                backgroundSize: "cover",
                                filter: "blur(12px)",
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
      </div>

      <div className="clearfix" />

      {/*
      <div
        className="video-container d-none"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          zIndex: -1,
        }}
      >
        <video
          autoPlay
          muted
          playsInline
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        >
          <source src="dart_animation.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      */}

      <div
        className="container"
        style={{
          padding: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="row d-none">
          <div className="col-sm-8 py-4 my-2 rounded">
            <h1>Velkommen til Tromsø Dartklubb!</h1>
            <p>Klubbkveld hver torsdag på Tromsø Bybowling!</p>
          </div>
          <div
            className="col-sm-4 rounded py-4"
            style={{ backgroundColor: "rgba(33, 38, 50, 0.45" }}
          >
            <img
              src="https://tromsodartklubb.no/TDKlogo.png"
              className="img-fluid rounded"
            />
          </div>
        </div>
        <div
          className="row justify-content-center my-4 py-4 rounded shadow-lg text-center"
          style={{
            backgroundColor: "rgba(33, 38, 39, 0.95)",
            position: "relative",
          }}
        >
          <h2
            className="text-center"
            style={{ color: "#FAFAFA", letterSpacing: 1 }}
          >
            Tromsø Dartklubb - Styret
          </h2>
          <div className="col-sm-4 py-4">
            <h4 className="board-title">Klubbleder</h4>
            <h6 className="board-name">Rita Elin Beck-Hauglid</h6>
          </div>
          <div className="col-sm-4 py-4">
            <h4 className="board-title">Nestleder</h4>
            <h6 className="board-name">Nicolai Martinussen</h6>
          </div>
          <div className="col-sm-4 py-4">
            <h4 className="board-title">Kasserer</h4>
            <h6 className="board-name">Svein Arvid Nygård</h6>
          </div>
          <div className="col-sm-3 py-4">
            <h4 className="board-title">Sekretær</h4>
            <h6 className="board-name">Martin Frantzen</h6>
          </div>
          <div className="col-sm-3 py-4">
            <h4 className="board-title">Styremedlem</h4>
            <h6 className="board-name">Merethe Karlsen</h6>
          </div>
          <div className="col-sm-3 py-4">
            <h4 className="board-title">Styremedlem</h4>
            <h6 className="board-name">Kjetil Edvardsen</h6>
          </div>
          <div className="col-sm-3 py-4">
            <h4 className="board-title">Varamedlem</h4>
            <h6 className="board-name">Christian Wilhelmsen</h6>
          </div>
          <FooterSVG />
        </div>
      </div>
    </>
  );
}

function FooterSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      preserveAspectRatio="xMidYMax slice"
      viewBox="0 0 1600 900"
      style={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        display: "block",
      }}
    >
      <defs>
        <linearGradient id="bg">
          <stop offset="0%" stopColor="rgba(130, 158, 249, 0.06)"></stop>
          <stop offset="50%" stopColor="rgba(76, 190, 255, 0.2)"></stop>
          <stop offset="100%" stopColor="rgba(115, 209, 72, 0.2)"></stop>
        </linearGradient>
        <path
          id="wave"
          fill="url(#bg)"
          d="M-363.852 502.589s236.988-41.997 505.475 0 371.981 38.998 575.971 0 293.985-39.278 505.474 5.859 493.475 48.368 716.963-4.995v560.106H-363.852v-560.97z"
        ></path>
      </defs>
      <use opacity="0.3" xlinkHref="#wave">
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          calcMode="spline"
          dur="10s"
          keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
          keyTimes="0; .5; 1"
          repeatCount="indefinite"
          type="translate"
          values="270 230; -334 180; 270 230"
        ></animateTransform>
      </use>
      <use opacity="0.6" xlinkHref="#wave">
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          calcMode="spline"
          dur="8s"
          keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
          keyTimes="0; .6; 1"
          repeatCount="indefinite"
          type="translate"
          values="-270 230;243 220;-270 230"
        ></animateTransform>
      </use>
      <use xlinkHref="#wave">
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          calcMode="spline"
          dur="6s"
          keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
          keyTimes="0; .4; 1"
          repeatCount="indefinite"
          type="translate"
          values="0 230;-140 200;0 230"
        ></animateTransform>
      </use>
    </svg>
  );
}
