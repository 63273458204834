import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../css/style.css";

export default function Container({ children, isLoggedIn }) {
  // Meny CSS Styling
  const [MobileMenu, setMobileMenu] = useState(false);
  const [MobileDropDown, setMobileDropDown] = useState("");

  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <a className="nav-link" href="/">
              <img
                src={process.env.PUBLIC_URL + "/TDKlogo.png"}
                alt="Tromsø Dartklubb"
                className="img-fluid"
              />
            </a>
          </div>
          <nav
            id="navbar"
            className={MobileMenu ? "navbar navbar-mobile" : "navbar"}
          >
            <ul>
              <li>
                <Link
                  onClick={() => setMobileMenu(false)}
                  className="nav-link"
                  to="/./"
                >
                  <i
                    className="bi bi-house"
                    style={{ color: "rgb(25, 42, 51)" }}
                  />
                  &nbsp;Hjem
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => setMobileMenu(false)}
                  className="nav-link"
                  to="/nyheter"
                >
                  <i
                    className="bi bi-newspaper"
                    style={{ color: "rgb(25, 42, 51)" }}
                  />
                  &nbsp;Nyheter
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => setMobileMenu(false)}
                  className="nav-link"
                  to="/tour"
                >
                  <i
                    className="bi bi-play-fill"
                    style={{ color: "rgb(25, 42, 51)" }}
                  />
                  &nbsp;Turneringer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => setMobileMenu(false)}
                  className="nav-link"
                  to="/stats"
                >
                  <i
                    className="bi bi-percent"
                    style={{ color: "rgb(25, 42, 51)" }}
                  />
                  &nbsp;Statistikk
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => setMobileMenu(false)}
                  className="nav-link"
                  to="/galleri"
                >
                  <i
                    className="bi bi-images"
                    style={{ color: "rgb(25, 42, 51)" }}
                  />
                  &nbsp;Galleri
                </Link>
              </li>
            </ul>
            <i
              className={
                MobileMenu
                  ? "bi mobile-nav-toggle bi-x"
                  : "bi bi-list mobile-nav-toggle"
              }
              onClick={() => setMobileMenu(!MobileMenu)}
            ></i>
          </nav>
        </div>
      </header>

      <main id="main">
        <section className="breadcrumbs" style={{ display: "block" }}>
          <div className="container"></div>
        </section>

        {children}
      </main>

      <footer id="footer" style={{ zIndex: 999 }}>
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 text-lg-left text-center">
              <div className="copyright">
                © Copyright <strong>Tromsø Dartklubb</strong>{" "}
                {isLoggedIn ? (
                  <>
                    <Link to={"../admin"}>[ADMIN]</Link>
                  </>
                ) : (
                  <Link to="../logginn">Logg inn</Link>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <nav className="footer-links text-lg-right text-center pt-2 pt-lg-0">
                System:{" "}
                <strong>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/in/erik-nilsen-247806164/"
                  >
                    Erik Nilsen
                  </a>
                </strong>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

function FooterSVG() {
  return (
    <body>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0"
        y="0"
        preserveAspectRatio="xMidYMax slice"
        viewBox="0 0 1600 900"
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          height: "25%",
          boxSizing: "border-box",
          display: "block",
        }}
      >
        <defs>
          <linearGradient id="bg">
            <stop offset="0%" stopColor="rgba(130, 158, 249, 0.06)"></stop>
            <stop offset="50%" stopColor="rgba(76, 190, 255, 0.6)"></stop>
            <stop offset="100%" stopColor="rgba(115, 209, 72, 0.2)"></stop>
          </linearGradient>
          <path
            id="wave"
            fill="url(#bg)"
            d="M-363.852 502.589s236.988-41.997 505.475 0 371.981 38.998 575.971 0 293.985-39.278 505.474 5.859 493.475 48.368 716.963-4.995v560.106H-363.852v-560.97z"
          ></path>
        </defs>
        <use opacity="0.3" xlinkHref="#wave">
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            calcMode="spline"
            dur="10s"
            keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
            keyTimes="0; .5; 1"
            repeatCount="indefinite"
            type="translate"
            values="270 230; -334 180; 270 230"
          ></animateTransform>
        </use>
        <use opacity="0.6" xlinkHref="#wave">
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            calcMode="spline"
            dur="8s"
            keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
            keyTimes="0; .6; 1"
            repeatCount="indefinite"
            type="translate"
            values="-270 230;243 220;-270 230"
          ></animateTransform>
        </use>
        <use xlinkHref="#wave">
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            calcMode="spline"
            dur="6s"
            keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
            keyTimes="0; .4; 1"
            repeatCount="indefinite"
            type="translate"
            values="0 230;-140 200;0 230"
          ></animateTransform>
        </use>
      </svg>
    </body>
  );
}
