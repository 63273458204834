import React, { useEffect, useState } from "react";

export default function BracketProvider({ data, isAdmin, regRes }) {
  const [numRounds, setNumRounds] = useState(0);
  const [pickedPlayer, pickPlayer] = useState("");

  useEffect(() => {
    getRound(data);
  }, [data]);

  const getRound = (data) => {
    let rounds = [];
    for (let i = 0; i < data.length; i++) {
      const index = data[i].id.indexOf("-");
      const numbersBeforeHyphen =
        index !== -1 ? data[i].id.substring(0, index) : data[i].id;
      if (!rounds.includes(numbersBeforeHyphen)) {
        rounds.push(numbersBeforeHyphen);
      }
    }
    setNumRounds(rounds);
  };

  const getMatch = (round) => {
    let content = [];

    for (let i = 0; i < data.length; i++) {
      const index = data[i].id.indexOf("-");
      const numbersBeforeHyphen =
        index !== -1 ? data[i].id.substring(0, index) : data[i].id;

      if (numbersBeforeHyphen === round) {
        content.push(
          <>
            <div
              className="p-2 my-2"
              style={{
                background: "rgb(71, 90, 102)",
                backgroundColor:
                  "linear-gradient(0deg, rgba(71,90,102,1) 0%, rgba(25,42,51,1) 50%, rgba(71,90,102,1) 100%)",
                cursor: "default ",
              }}
              onClick={() => {
                if (isAdmin) {
                  regRes(data[i].p1, data[i].p2);
                }
              }}
            >
              <div className="d-flex flex-row justify-content-between">
                <div
                  className="p-2 text-truncate"
                  onMouseEnter={() => pickPlayer(data[i].p1)}
                  onMouseLeave={() => pickPlayer(null)}
                  style={
                    pickedPlayer === data[i].p1
                      ? { color: "gold" }
                      : { color: "lightgrey" }
                  }
                >
                  {data[i].p1}
                </div>
                <div className="p-2">
                  {data[i].p1 === data[i].winner ? (
                    <i
                      className="bi bi-trophy-fill"
                      style={{ color: "gold" }}
                    />
                  ) : (
                    <i className="bi bi-x" style={{ color: "black" }} />
                  )}
                </div>
              </div>
              <div
                className="d-flex justify-content-center roundNumber"
                style={{ fontSize: 10, color: "silver", letterSpacing: 2 }}
              >
                {round == 2
                  ? "Finale"
                  : round == 4
                  ? "Semifinale"
                  : "Runde " + round}
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div
                  className="p-2 text-truncate"
                  onMouseEnter={() => pickPlayer(data[i].p2)}
                  onMouseLeave={() => pickPlayer(null)}
                  style={
                    pickedPlayer === data[i].p2 && pickedPlayer != "WO"
                      ? { color: "gold" }
                      : { color: "lightgrey" }
                  }
                >
                  {data[i].p2}
                </div>
                <div className="p-2">
                  {data[i].p2 === data[i].winner ? (
                    <i
                      className="bi bi-trophy-fill"
                      style={{ color: "gold" }}
                    />
                  ) : (
                    <i className="bi bi-x" style={{ color: "black" }} />
                  )}
                </div>
              </div>
            </div>
          </>
        );
      }
    }

    return content;
  };

  return (
    <div className="container overflow-auto">
      <div className="d-flex flex-row">
        {numRounds && (
          <>
            {numRounds.map((round, k) => {
              return (
                <React.Fragment key={"hh" + k}>
                  <div className="d-flex flex-fill flex-column">
                    <div
                      className="d-flex p-2 justify-content-center"
                      style={{
                        backgroundColor: "#306987",
                        margin: 5,
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        {round == 2
                          ? "Finale"
                          : round == 4
                          ? "Semifinale"
                          : "Runde " + round}
                      </div>
                    </div>

                    <div className="d-flex flex-fill justify-content-around flex-column m-2 endGameRound">
                      {getMatch(round)}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
