import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

import { Modal as BModal, Button } from "react-bootstrap";

import {
  SingleEliminationBracket,
  Match,
  SVGViewer,
  createTheme,
} from "@g-loot/react-tournament-brackets";

import BracketProvider from "../hooks/BracketProvider";

export default function Tournament({ isLoggedIn }) {
  const props = useParams();
  const isAdmin = isLoggedIn;
  const PlayerTableRef = useRef();

  const [loadedPlayers, setLoadedPlayers] = useState([]); // Spillere fra <PlayerTable />, brukes av Outs

  function updateResults() {
    PlayerTableRef.current.updateResults();
  }

  useEffect(() => {
    const header = document.getElementById("header");
    header.classList.remove("fixed-top");

    const breadcrumbs = document.getElementsByClassName("breadcrumbs");
    breadcrumbs[0].style.marginTop = "0px";

    const main = document.getElementById("main");
    main.style.marginTop = "0px";

    document.title = "Turneringer - Tromsø Dartklubb";

    return () => {
      header.classList.add("fixed-top");
      breadcrumbs[0].style.marginTop = "86px";
      main.style.marginTop = "86px";
    };
  }, []);

  // Register result
  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");

  const [roundId, setRoundId] = useState(0);

  // Effects to update props to res (Modal)
  function updateRegInfo(type, data) {
    if (type === "p1") {
      setP1(data);
    }
    if (type === "p2") {
      setP2(data);
    }
    if (type === "round") {
      setRoundId(data);
    }
  }

  // Check if Tournament has started (round == 1)
  const [tourStarted, setTourStarted] = useState(false);
  const [tourName, setTourName] = useState("");
  const [tourPlayers, setTourPlayers] = useState([]);

  // Click 2 times "Start Tour" to begin
  const [clickStart, setClickStart] = useState(0);

  useEffect(() => {
    const startTour = async () => {
      try {
        const res = await fetch(
          `https://tromsodartklubb.no/api/genMatches.php?id=${props.id}`
        );
        getTourInfo();
      } catch (err) {
        alert("Noe gikk galt...");
      }
    };

    if (clickStart === 1) {
      alert("Trykk 'Start turnering' igjen for å starte");
    }

    if (clickStart === 2) {
      startTour();
    }
  }, [clickStart]);

  useEffect(() => {
    if (props.id) {
      getTourInfo();
      getOuts(); // Info til outs
      if (!tourStarted) {
        getAllPlayers();
      }
    } else {
      document.title = "Turneringer - Tromsø Dartklubb";
    }
  }, [props.id]);

  const getTourInfo = async () => {
    try {
      const res = await fetch(
        `https://tromsodartklubb.no/api/getTourInfo.php?id=${props.id}`
      );
      const data = await res.json();

      setTourStarted(data.started);
      setTourName(data.name);
      setTourPlayers(data.players);

      document.title = data.name + " - Tromsø Dartklubb";
    } catch (err) {
      return null;
    }
  };

  const [newPlayer, setNewPlayer] = useState("");

  const addPlayer = async (nPlayer) => {
    try {
      const dataToSend = {
        tID: parseInt(props.id),
        player: nPlayer ? nPlayer : newPlayer,
        // Legg til flere variabler her etter behov
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Endre content type avhengig av serverens krav
          // Legg til andre nødvendige headers her
        },
        body: JSON.stringify(dataToSend),
      };

      const res = await fetch(
        "https://tromsodartklubb.no/api/addPlayer.php",
        requestOptions
      );
      setNewPlayer("");
      getTourInfo();
    } catch (err) {
      console.log(err);
    }
  };

  const removePlayer = async (player) => {
    try {
      const dataToSend = {
        tID: parseInt(props.id),
        player: player,
        // Legg til flere variabler her etter behov
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Endre content type avhengig av serverens krav
          // Legg til andre nødvendige headers her
        },
        body: JSON.stringify(dataToSend),
      };

      const res = await fetch(
        "https://tromsodartklubb.no/api/removePlayer.php",
        requestOptions
      );
      setNewPlayer("");
      getTourInfo();
    } catch (err) {
      console.log(err);
    }
  };

  const addNewPlayerInGame = async () => {
    const playerName = window.prompt("Spillernavn: ");

    if (playerName) {
      try {
        const dataToSend = {
          tID: parseInt(props.id),
          player: playerName,
          // Legg til flere variabler her etter behov
        };

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Endre content type avhengig av serverens krav
            // Legg til andre nødvendige headers her
          },
          body: JSON.stringify(dataToSend),
        };

        const res = await fetch(
          "https://tromsodartklubb.no/api/addPlayerToMatch.php",
          requestOptions
        );

        window.location.reload(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  // Outs
  const [showOutsAdmin, setShowOutsAdmin] = useState(false);
  const [highestOut, setHighestOut] = useState(0);
  const [highestOutPerson, setHighestOutPerson] = useState("Anders");
  const [fastestLeg, setFastestLeg] = useState(0);
  const [fastestLegPerson, setFastestLegPerson] = useState("");

  const highestOutPersonRef = useRef(null);
  const fastestLegPersonRef = useRef(null);

  const getOuts = async () => {
    try {
      const res = await fetch(
        `https://tromsodartklubb.no/api/addGameInfo.php?tID=${props.id}`
      );
      const data = await res.json();
      setHighestOut(parseInt(data[0].highestOut));
      setHighestOutPerson(parseInt(data[0].highestOutPerson));
      setFastestLeg(parseInt(data[0].fastestLeg));
      setFastestLegPerson(parseInt(data[0].fastestLegPerson));

      if (highestOutPersonRef.current && data[0].highestOutPerson) {
        highestOutPersonRef.current.value = data[0].highestOutPerson;
      }
      if (fastestLegPersonRef.current && data[0].fastestLegPerson) {
        fastestLegPersonRef.current.value = data[0].fastestLegPerson;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const addOutsToGame = async () => {
    try {
      const dataToSend = {
        tID: parseInt(props.id),
        highestOut: highestOut ? parseInt(highestOut) : parseInt(0),
        highestOutPerson: highestOutPerson,
        fastestLeg: fastestLeg ? parseInt(fastestLeg) : parseInt(0),
        fastestLegPerson: fastestLegPerson,
        // Legg til flere variabler her etter behov
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Endre content type avhengig av serverens krav
          // Legg til andre nødvendige headers her
        },
        body: JSON.stringify(dataToSend),
      };

      const res = await fetch(
        "https://tromsodartklubb.no/api/addGameInfo.php",
        requestOptions
      );

      if (res.status == 200) {
        setShowOutsAdmin(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const generateMatches = async () => {
    try {
      const res = await fetch(
        `https://tromsodartklubb.no/api/genMatches.php?id=${props.id}`
      );
      window.location.reload(false);
    } catch (err) {
      alert("Noe gikk galt...");
    }
  };

  const [allPlayers, setAllPlayers] = useState([]);
  const getAllPlayers = async () => {
    try {
      const res = await fetch(
        `https://tromsodartklubb.no/api/getAllPlayers.php`
      );
      const data = await res.json();
      setAllPlayers(data);
    } catch (err) {
      alert("Noe gikk galt...");
    }
  };

  return (
    <>
      <>
        {props.id ? (
          <>
            {tourStarted ? (
              <>
                {/* Turnering er startet */}
                <div className="container">
                  <div className="row">
                    <>
                      <div className="col-sm-8" style={{ padding: 5 }}>
                        <h2>Turnering: {tourName}</h2>
                      </div>
                      {isAdmin ? (
                        <div className="col-sm" style={{ padding: 5 }}>
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginRight: 5 }}
                            onClick={() => addNewPlayerInGame()}
                          >
                            Legg til spiller
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{ marginRight: 25 }}
                            onClick={() => {
                              setShowOutsAdmin((prev) => !prev);
                            }}
                          >
                            Legg til outs
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => generateMatches()}
                          >
                            Generer sluttspill
                          </button>
                        </div>
                      ) : null}
                    </>
                  </div>
                  {showOutsAdmin && (
                    <>
                      <hr />
                      <div className="row">
                        <div className="col-sm-6">
                          <h6>Høyeste utgang</h6>
                          <div className="input-group mb-3">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Høyeste utgang"
                              value={highestOut && highestOut}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onChange={(e) => setHighestOut(e.target.value)}
                            />
                          </div>
                          <div className="input-group mb-3">
                            <select
                              className="form-select"
                              id="inputGroupSelect01"
                              onChange={(e) =>
                                setHighestOutPerson(e.target.value)
                              }
                              ref={highestOutPersonRef}
                            >
                              <option disabled>Velg spiller</option>
                              {loadedPlayers.map((player, playerKey) => {
                                return (
                                  <option
                                    key={player.dummyID}
                                    value={player.player}
                                    selected={
                                      player.player === highestOutPerson
                                        ? true
                                        : false
                                    }
                                  >
                                    {player.player}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <h6>Raskeste leg</h6>
                          <div className="input-group mb-3">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Raskeste leg"
                              value={fastestLeg && fastestLeg}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onChange={(e) => setFastestLeg(e.target.value)}
                            />
                          </div>
                          <div className="input-group mb-3">
                            <select
                              className="form-select"
                              id="inputGroupSelect01"
                              onChange={(e) =>
                                setFastestLegPerson(e.target.value)
                              }
                              ref={fastestLegPersonRef}
                            >
                              <option disabled>Velg spiller</option>
                              {loadedPlayers.map((player, playerKey) => {
                                return (
                                  <option
                                    key={player.dummyID}
                                    value={player.player}
                                    selected={
                                      player.player === fastestLegPerson
                                        ? true
                                        : false
                                    }
                                  >
                                    {player.player}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => addOutsToGame()}
                          >
                            Lagre info
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <hr />
                  <PlayerTable
                    tourID={props.id}
                    isAdmin={isAdmin}
                    updateRegInfo={updateRegInfo}
                    ref={PlayerTableRef}
                    setLoadedPlayers={setLoadedPlayers}
                  />
                </div>
                <div className="container-fluid">
                  <EndGameBracket tourID={props.id} isAdmin={isAdmin} />
                </div>
                <Modal
                  tourID={props.id}
                  p1={p1}
                  p2={p2}
                  roundId={roundId}
                  updateResults={updateResults}
                />
              </>
            ) : (
              <>
                <div className="container">
                  {/* Turnering ikke startet */}
                  <div className="row">
                    <div className="col-sm-8" style={{ padding: 5 }}>
                      <h2> Turnering:{tourName}</h2>
                    </div>
                    {isAdmin ? (
                      <>
                        <div className="col-sm" style={{ padding: 5 }}>
                          <button
                            type="button"
                            style={{ margin: 5 }}
                            className="btn btn-success"
                            data-bs-toggle="modal"
                            data-bs-target="#addPlayer"
                          >
                            Legg til spiller
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setClickStart((prev) => prev + 1)}
                          >
                            Start turnering
                          </button>
                        </div>

                        {/* Legg til spiller */}
                        <div
                          className="modal fade"
                          id="addPlayer"
                          tabIndex={-1}
                          aria-labelledby="addPlayer"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  Legg til spiller
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                />
                              </div>
                              <div className="modal-body">
                                <div className="input-group mb-3">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    Spillernavn
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-describedby="basic-addon1"
                                    value={newPlayer}
                                    onChange={(e) =>
                                      setNewPlayer(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Lukk
                                </button>
                                <button
                                  type="button"
                                  data-bs-dismiss="modal"
                                  className="btn btn-primary"
                                  onClick={() => addPlayer()}
                                >
                                  Legg til
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <hr />
                  <h4>Spillere i denne turneringen:</h4>

                  <ul className="list-group list-group-flush">
                    {tourPlayers.map((player, key) => {
                      return (
                        <li key={key} className="list-group-item">
                          {player}
                          {isAdmin && (
                            <i
                              className="bi bi-x-circle"
                              style={{
                                float: "right",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() => isAdmin && removePlayer(player)}
                            />
                          )}
                        </li>
                      );
                    })}
                  </ul>

                  <br />
                  <hr />
                  {isAdmin && (
                    <>
                      <h5>Legg til spillere i turneringen</h5>

                      <div className="row">
                        {allPlayers.map((player, key) => {
                          // Sjekk om player.player finnes i players
                          if (
                            tourPlayers.some(
                              (existingPlayer) =>
                                existingPlayer === player.player
                            )
                          ) {
                            return null; // Hopper over dette elementet i løkken
                          }
                          return (
                            <div
                              className="col-6 col-md-3"
                              key={key}
                              onClick={() => {
                                addPlayer(player.player);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="card p-3 m-2">
                                <span>{player.player}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <Tournaments isAdmin={isAdmin} />
        )}
      </>
    </>
  );
}

const Tournaments = ({ isAdmin }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const p = searchParams.get("p");

  const [data, setData] = useState([]);
  const [numTours, setNumTours] = useState(0);

  useEffect(() => {
    getData();
  }, [p]);

  async function getData() {
    try {
      if (p) {
        const res = await fetch(
          `https://tromsodartklubb.no/api/getTournaments.php?p=${p}`
        );
        const data = await res.json();

        setData(data);
        setNumTours(data[0]["numTours"]);
      } else {
        const res = await fetch(
          `https://tromsodartklubb.no/api/getTournaments.php`
        );
        const data = await res.json();

        setData(data);
        setNumTours(data[0]["numTours"]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const newTour = async () => {
    const tourName = window.prompt("Velg turneringsnavn:");
    if (tourName) {
      try {
        const dataToSend = {
          tourName: tourName,
        };

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Endre content type avhengig av serverens krav
            // Legg til andre nødvendige headers her
          },
          body: JSON.stringify(dataToSend),
        };

        const res = await fetch(
          "https://tromsodartklubb.no/api/newTour.php",
          requestOptions
        );
        const data = await res.json();

        getData();
      } catch (err) {
        alert("Noe gikk galt.. Prøv igjen");
      }
    }
  };

  const TourNumPage = () => {
    if (numTours) {
      const numPages = Math.round(numTours / 8);
      let pages = [];

      for (let i = 1; i <= numPages; i++) {
        pages.push(i);
      }

      if (pages) {
        return (
          <nav>
            <ul className="pagination">
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  style={{ backgroundColor: "#475A66", color: "white" }}
                >
                  <span aria-hidden="true">«</span>
                </a>
              </li>

              {pages.map((pp, ppKey) => {
                return (
                  <li className="page-item" key={ppKey}>
                    <Link
                      className="page-link"
                      to={p && pp == 1 ? "./" : "?p=" + pp}
                      style={
                        p == pp || (!p && pp == 1)
                          ? { backgroundColor: "#475A66", color: "#3498db" }
                          : { backgroundColor: "#475A66", color: "#FAFAFA" }
                      }
                    >
                      {pp}
                    </Link>
                  </li>
                );
              })}
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  style={{ backgroundColor: "#475A66", color: "white" }}
                >
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>
        );
      } else {
        return null;
      }
    }
  };

  const formatDate = (created) => {
    const date = new Date(created);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} kl ${hours}:${minutes}`;
  };

  return (
    <div className="container">
      <div className="col-sm">
        <div className="row py-2">
          <div className="col-sm-10">
            <h2>Turneringer</h2>
          </div>
          <div className="col-sm-2">
            {isAdmin && (
              <button
                type="button"
                onClick={() => newTour()}
                className="btn btn-success"
              >
                Legg til ny turnering
              </button>
            )}
          </div>
        </div>
        <div className="row d-flex flex-fill">
          {data.map((d, k) => {
            return (
              <div className="col-sm-6 d-flex flex-grow my-2" key={k}>
                <Link to={"./" + d.id}>
                  <div className="card mb-3 d-flex flex-grow-1 h-100 flex-fill">
                    <div className="row g-0 flex-grow-1">
                      <div className="col-md-4 d-flex">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/f/fb/Darts_in_a_dartboard.jpg"
                          className="img-fluid rounded-start"
                          alt="Dart"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="card-body">
                          <h5 className="card-title">{d.name}</h5>
                          <p className="card-text">
                            {d.winner && (
                              <p>
                                <i
                                  style={{ color: "#5d7d84" }}
                                  className="bi bi-trophy"
                                />{" "}
                                Vinner: {d.winner}
                              </p>
                            )}
                            {d.highestOutPerson && (
                              <p>
                                <i
                                  style={{ color: "#5d7d84" }}
                                  className="bi bi-check2-circle"
                                />{" "}
                                Høyeste utgang: {d.highestOut} -{" "}
                                {d.highestOutPerson}
                              </p>
                            )}
                            {d.fastestLegPerson && (
                              <p>
                                <i
                                  style={{ color: "#5d7d84" }}
                                  className="bi bi-check2-circle"
                                />{" "}
                                Raskeste leg: {d.fastestLeg} -{" "}
                                {d.fastestLegPerson}
                              </p>
                            )}

                            <small className="text-muted">
                              Opprettet {formatDate(d.created)}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-center">
          <TourNumPage />
        </div>
      </div>
    </div>
  );
};

const PlayerTable = forwardRef(
  ({ tourID, isAdmin, updateRegInfo, setLoadedPlayers }, ref) => {
    const [hasLoaded, setLoaded] = useState(false);
    const [error, setError] = useState("Laster...");

    const [players, setPlayers] = useState([]);
    const [results, setResults] = useState([]);
    const [results2, setResults2] = useState([]);
    const [results3, setResults3] = useState([]);

    const [placement, setPlacement] = useState([]);

    const gameHeadRef = useRef();

    /*
  useEffect(() => {
    if (gameHeadRef.current) {
      const target = document.querySelector('[id="gameHead"]');
      window.scrollTo({
        top: Math.round(
          target.getBoundingClientRect().top +
            document.documentElement.scrollTop
        ),
        behavior: "smooth",
      });
    }
  }, [hasLoaded]); // Tomt avhengighetsarray betyr at useEffect kjører bare ved montering og avmontering
  */

    // Scroll effect for tabellen
    /*
  useEffect(() => {
    if (gameHeadRef.current) {
      const target = document.querySelector('[id="gameHead"]');
      const scrollTopTarget = Math.round(
        target.getBoundingClientRect().top + document.documentElement.scrollTop
      );

      const handleScroll = () => {
        var currentScrollPosition = window.scrollY;

        if (currentScrollPosition < scrollTopTarget) {
          target.style.position = null;
        } else {
          target.style.position = "fixed";
          //target.style.top = 0;
          //target.style.right = 0;
          //target.style.left = 0;
          target.style.zIndex = 999;

          // Hent referansen til <thead> og <tbody> elementene
          const theadColumns = document.querySelectorAll("thead th");
          const tbodyColumns = document.querySelectorAll("tbody td");

          // Loop gjennom kolonnene og sett bredden på <thead> kolonner tilsvarende <tbody> kolonner
          theadColumns.forEach((theadColumn, index) => {
            const tbodyColumnWidth = tbodyColumns[index].offsetWidth;
            theadColumn.style.width = `${tbodyColumnWidth}px`;
          });
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasLoaded]);
  */

    useEffect(() => {
      getPlayers();
      getResults();
    }, [tourID]);

    // Gi child-komponenten en ref
    React.useImperativeHandle(ref, () => ({
      updateResults() {
        // Implementer oppdateringslogikk her
        getResults();
      },
    }));

    useEffect(() => {
      const int1 = setInterval(genRounds, 2500);
      const int2 = setInterval(getResults, 5500);

      return () => {
        clearInterval(int1);
        clearInterval(int2);
      };
    }, []);

    async function genRounds() {
      try {
        await fetch(
          `https://tromsodartklubb.no/api/genNextRound.php?id=${tourID}`
        );
      } catch (err) {
        console.log(err);
      }
    }

    async function getPlayers() {
      setLoaded(false);
      try {
        const res = await fetch(
          `https://tromsodartklubb.no/api/getPlayers.php?id=${tourID}`
        );
        const data = await res.json();
        setPlayers(data);
        setLoadedPlayers(data); // Denne er til parent
        setLoaded(true);
      } catch (err) {
        setPlayers([]);
        //console.log(err);
        setError("Noe gikk galt.. Kanskje turneringen ikke finnes?");
      }
    }

    async function getResults() {
      try {
        // Runde 1
        const res = await fetch(
          `https://tromsodartklubb.no/api/getResults.php?id=${tourID}&round=1`
        );
        const data = await res.json();
        setResults(data);
      } catch (err) {
        console.log(err);
      }

      try {
        const res2 = await fetch(
          `https://tromsodartklubb.no/api/getResults.php?id=${tourID}&round=2`
        );
        const data2 = await res2.json();
        setResults2(data2);
      } catch (err) {
        console.log(err);
      }

      try {
        const res3 = await fetch(
          `https://tromsodartklubb.no/api/getResults.php?id=${tourID}&round=3`
        );
        const data3 = await res3.json();
        setResults3(data3);
      } catch (err) {
        console.log(err);
      }

      try {
        const place = await fetch(
          `https://tromsodartklubb.no/api/getPlace.php?id=${tourID}`
        );
        const placeData = await place.json();
        setPlacement(placeData);
      } catch (err) {
        console.log(err);
      }
    }

    const getInfo = (player, type, round) => {
      let result;
      if (round === 1) {
        result = results.find((res) => res.curPlayer === player);
      }
      if (round === 2) {
        result = results2.find((res2) => res2.curPlayer === player);
      }
      if (round === 3) {
        result = results3.find((res3) => res3.curPlayer === player);
      }

      if (result) {
        if (type === "motstander") {
          return result.p1 === player ? result.p2 : result.p1;
        }
        if (type === "resultat") {
          if (result.winner) {
            // Må legge inn vinner
            if (
              (result.resultat1 !== "0" && result.resultat2 !== "0") ||
              (result.resultat1 !== 0 && result.resultat2 !== 0)
            ) {
              if (result.p1 === player) {
                return result.resultat1 + " - " + result.resultat2;
              }
              if (result.p2 === player) {
                return result.resultat2 + " - " + result.resultat1;
              }
            }

            return result.winner;
          } else {
            return null;
          }
        }

        if (type === "legWon") {
          return result.p1 === player ? result.resultat1 : result.resultat2;
        }

        if (type === "legLost") {
          return result.p1 === player ? result.resultat2 : result.resultat1;
        }

        if (type === "legDiff") {
          return result.p1 === player
            ? result.resultat1 - result.resultat2
            : result.resultat2 - result.resultat1;
        }

        if (type === "poeng") {
          if (result.winner) {
            if (result.winner === player) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      }

      // Håndter tilfellet der ingen match ble funnet, for eksempel returner en feilmelding eller annen passende respons
      return null;
    };

    const getPlacement = (player) => {
      if (placement) {
        const place = placement.find((res) => res.player === player);
        const placeIndex = placement.findIndex((res) => res.player === player);
        return placeIndex + 1;
      } else {
        return null;
      }
    };

    const [activeMatches, setActiveMatches] = useState([]);

    // Scrollbar table
    const LeftTable = useRef();
    const RightTable = useRef();

    const handleRightTableScroll = () => {
      if (LeftTable.current && RightTable.current) {
        const scrollTop = RightTable.current.scrollTop;
        LeftTable.current.scrollTop = scrollTop;
      }
    };

    return (
      <>
        {!hasLoaded ? (
          <div>{error}</div>
        ) : (
          <div className="row">
            <div
              className="col-4 col-md-2"
              style={{
                overflow: "hidden",
                overflowX: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <div
                ref={LeftTable}
                className="table-responsive"
                style={
                  (tableContainerStyle,
                  { overflow: "hidden", overflowX: "auto", maxHeight: "85vh" })
                }
              >
                <table
                  className="table table-striped table-hover table-borderless"
                  style={tableStyle}
                >
                  <thead id="gameHead" ref={gameHeadRef}>
                    <tr className="tableHead">
                      <th
                        colSpan="1"
                        style={(fixedCellStyle, { backgroundColor: "#192a33" })}
                      >
                        &nbsp;
                      </th>
                    </tr>
                    <tr className="tableHead" style={{ textAlign: "center" }}>
                      <th
                        scope="col"
                        style={
                          (fixedCellStyle,
                          {
                            backgroundColor: "#192a33",
                            color: "#FAFAFA",
                            textAlign: "left",
                          })
                        }
                      >
                        # NAVN
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {players && (
                      <>
                        {players.map((player, k) => {
                          return (
                            <tr key={k}>
                              <th
                                scope="row"
                                style={
                                  (fixedCellStyle,
                                  {
                                    backgroundColor: "#192a33",
                                    color: "#FAFAFA",
                                    textAlign: "left",
                                  })
                                }
                              >
                                {k + 1} - {player.player}
                              </th>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-8 col-md-10" style={{ whiteSpace: "nowrap" }}>
              <div
                ref={RightTable}
                onScroll={handleRightTableScroll}
                className="table-responsive"
                style={
                  (tableContainerStyle, { overflow: "auto", maxHeight: "85vh" })
                }
              >
                <table
                  className="table table-striped table-hover table-borderless"
                  style={tableStyle}
                >
                  <thead id="gameHead" ref={gameHeadRef}>
                    <tr className="tableHead">
                      <th
                        colSpan="3"
                        style={{
                          backgroundColor: "#384563",
                          color: "white",
                          textAlign: "center",
                          borderTopLeftRadius: 5,
                        }}
                      >
                        Runde 1
                      </th>
                      <th
                        colSpan="4"
                        style={{
                          backgroundColor: "#535353",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Runde 2
                      </th>
                      <th
                        colSpan="4"
                        style={{
                          backgroundColor: "#657545",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Runde 3
                      </th>
                      <th
                        colSpan="3"
                        style={{
                          backgroundColor: "#97574d",
                          color: "white",
                          textAlign: "center",
                          borderTopRightRadius: 5,
                        }}
                      >
                        Sluttspill
                      </th>
                    </tr>
                    <tr className="tableHead" style={{ textAlign: "center" }}>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#2e3d54",
                          color: "lightgrey",
                        }}
                      >
                        Motst.
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#2e3d54",
                          color: "lightgrey",
                        }}
                      >
                        Resultat
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#2e3d54",
                          color: "lightgrey",
                        }}
                      >
                        Poeng
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#414749",
                          color: "lightgrey",
                        }}
                      >
                        Motst.
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#414749",
                          color: "lightgrey",
                        }}
                      >
                        Resultat
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#414749",
                          color: "lightgrey",
                        }}
                      >
                        Poeng
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#414749",
                          color: "lightgrey",
                        }}
                      >
                        Sum
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#4e5f3f",
                          color: "lightgrey",
                        }}
                      >
                        Motst.
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#4e5f3f",
                          color: "lightgrey",
                        }}
                      >
                        Resultat
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#4e5f3f",
                          color: "lightgrey",
                        }}
                      >
                        Poeng
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#4e5f3f",
                          color: "lightgrey",
                        }}
                      >
                        Sum
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#714a45",
                          color: "lightgrey",
                        }}
                      >
                        Sum Legg
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#714a45",
                          color: "lightgrey",
                        }}
                      >
                        Legg diff.
                      </th>
                      <th
                        scope="col"
                        style={{
                          backgroundColor: "#714a45",
                          color: "lightgrey",
                        }}
                      >
                        Nr
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {players && (
                      <>
                        {players.map((player, k) => {
                          return (
                            <tr key={k}>
                              <td
                                style={
                                  activeMatches.findIndex(
                                    (item) =>
                                      item ===
                                      "1-" +
                                        getInfo(player.player, "motstander", 1)
                                  ) !== -1
                                    ? {
                                        border: "2px solid #000",
                                        backgroundColor: "#384563",
                                        color: "lightgrey",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#384563",
                                        color: "lightgrey",
                                        cursor: "pointer",
                                      }
                                }
                                onClick={() => {
                                  const newMatch =
                                    "1-" +
                                    getInfo(player.player, "motstander", 1);
                                  const newMatch2 = "1-" + player.player;

                                  // Sjekk om match allerede eksisterer i activeMatches
                                  const matchExists =
                                    activeMatches.includes(newMatch);
                                  const matchExists2 =
                                    activeMatches.includes(newMatch2);

                                  if (matchExists || matchExists2) {
                                    // Fjern match fra activeMatches hvis den allerede finnes
                                    setActiveMatches((prev) =>
                                      prev.filter((item) => item !== newMatch)
                                    );
                                    setActiveMatches((prev) =>
                                      prev.filter((item) => item !== newMatch2)
                                    );
                                  } else {
                                    // Legg til match i activeMatches hvis den ikke finnes
                                    setActiveMatches((prev) => [
                                      ...prev,
                                      newMatch,
                                    ]);
                                    setActiveMatches((prev) => [
                                      ...prev,
                                      newMatch2,
                                    ]);
                                  }
                                }}
                              >
                                {getInfo(player.player, "motstander", 1)}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#384563",
                                  color: "lightgrey",
                                }}
                              >
                                {getInfo(player.player, "resultat", 1)}
                                {
                                  <>
                                    <>
                                      {isAdmin && (
                                        <i
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: 5,
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                          className="bi bi-pencil"
                                          onClick={() => {
                                            updateRegInfo("round", 1);
                                            updateRegInfo("p1", player.player);
                                            updateRegInfo(
                                              "p2",
                                              getInfo(
                                                player.player,
                                                "motstander",
                                                1
                                              )
                                            );
                                          }}
                                        />
                                      )}{" "}
                                    </>
                                  </>
                                }
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#384563",
                                  color: "lightgrey",
                                }}
                              >
                                {getInfo(player.player, "poeng", 1)}
                              </td>

                              <td
                                style={
                                  activeMatches.findIndex(
                                    (item) =>
                                      item ===
                                      "2-" +
                                        getInfo(player.player, "motstander", 2)
                                  ) !== -1
                                    ? {
                                        border: "1px solid #000",
                                        backgroundColor: "#535353",
                                        color: "lightgrey",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#535353",
                                        color: "lightgrey",
                                        cursor: "pointer",
                                      }
                                }
                                onClick={() => {
                                  const newMatch =
                                    "2-" +
                                    getInfo(player.player, "motstander", 2);
                                  const newMatch2 = "2-" + player.player;

                                  // Sjekk om match allerede eksisterer i activeMatches
                                  const matchExists =
                                    activeMatches.includes(newMatch);
                                  const matchExists2 =
                                    activeMatches.includes(newMatch2);

                                  if (matchExists || matchExists2) {
                                    // Fjern match fra activeMatches hvis den allerede finnes
                                    setActiveMatches((prev) =>
                                      prev.filter((item) => item !== newMatch)
                                    );
                                    setActiveMatches((prev) =>
                                      prev.filter((item) => item !== newMatch2)
                                    );
                                  } else {
                                    // Legg til match i activeMatches hvis den ikke finnes
                                    setActiveMatches((prev) => [
                                      ...prev,
                                      newMatch,
                                    ]);
                                    setActiveMatches((prev) => [
                                      ...prev,
                                      newMatch2,
                                    ]);
                                  }
                                }}
                              >
                                {getInfo(player.player, "motstander", 2)}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#535353",
                                  color: "lightgrey",
                                }}
                              >
                                {getInfo(player.player, "resultat", 2)}
                                {
                                  <>
                                    <>
                                      {isAdmin && (
                                        <>
                                          {getInfo(
                                            player.player,
                                            "motstander",
                                            2
                                          ) != null && (
                                            <i
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: 5,
                                              }}
                                              data-bs-toggle="modal"
                                              data-bs-target="#exampleModal"
                                              className="bi bi-pencil"
                                              onClick={() => {
                                                updateRegInfo("round", 2);
                                                updateRegInfo(
                                                  "p1",
                                                  player.player
                                                );
                                                updateRegInfo(
                                                  "p2",
                                                  getInfo(
                                                    player.player,
                                                    "motstander",
                                                    2
                                                  )
                                                );
                                              }}
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  </>
                                }
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#535353",
                                  color: "lightgrey",
                                }}
                              >
                                {getInfo(player.player, "poeng", 2)}
                              </td>

                              <td
                                style={{
                                  backgroundColor: "#535353",
                                  color: "lightgrey",
                                }}
                              >
                                {getInfo(player.player, "poeng", 1) +
                                  getInfo(player.player, "poeng", 2)}
                              </td>

                              <td
                                style={
                                  activeMatches.findIndex(
                                    (item) =>
                                      item ===
                                      "3-" +
                                        getInfo(player.player, "motstander", 3)
                                  ) !== -1
                                    ? {
                                        border: "1px solid #000",
                                        backgroundColor: "#657545",
                                        color: "lightgrey",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#657545",
                                        color: "lightgrey",
                                        cursor: "pointer",
                                      }
                                }
                                onClick={() => {
                                  const newMatch =
                                    "3-" +
                                    getInfo(player.player, "motstander", 3);
                                  const newMatch2 = "3-" + player.player;

                                  // Sjekk om match allerede eksisterer i activeMatches
                                  const matchExists =
                                    activeMatches.includes(newMatch);
                                  const matchExists2 =
                                    activeMatches.includes(newMatch2);

                                  if (matchExists || matchExists2) {
                                    // Fjern match fra activeMatches hvis den allerede finnes
                                    setActiveMatches((prev) =>
                                      prev.filter((item) => item !== newMatch)
                                    );
                                    setActiveMatches((prev) =>
                                      prev.filter((item) => item !== newMatch2)
                                    );
                                  } else {
                                    // Legg til match i activeMatches hvis den ikke finnes
                                    setActiveMatches((prev) => [
                                      ...prev,
                                      newMatch,
                                    ]);
                                    setActiveMatches((prev) => [
                                      ...prev,
                                      newMatch2,
                                    ]);
                                  }
                                }}
                              >
                                {getInfo(player.player, "motstander", 3)}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#657545",
                                  color: "lightgrey",
                                }}
                              >
                                {getInfo(player.player, "resultat", 3)}
                                {
                                  <>
                                    <>
                                      {isAdmin && (
                                        <>
                                          {getInfo(
                                            player.player,
                                            "motstander",
                                            3
                                          ) != null && (
                                            <i
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: 5,
                                              }}
                                              data-bs-toggle="modal"
                                              data-bs-target="#exampleModal"
                                              className="bi bi-pencil"
                                              onClick={() => {
                                                updateRegInfo("round", 3);
                                                updateRegInfo(
                                                  "p1",
                                                  player.player
                                                );
                                                updateRegInfo(
                                                  "p2",
                                                  getInfo(
                                                    player.player,
                                                    "motstander",
                                                    3
                                                  )
                                                );
                                              }}
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  </>
                                }
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#657545",
                                  color: "lightgrey",
                                }}
                              >
                                {getInfo(player.player, "poeng", 3)}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#657545",
                                  color: "lightgrey",
                                }}
                              >
                                {getInfo(player.player, "poeng", 1) +
                                  getInfo(player.player, "poeng", 2) +
                                  getInfo(player.player, "poeng", 3)}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#97574d",
                                  color: "#d1d1d1",
                                }}
                              >
                                {parseInt(getInfo(player.player, "legWon", 1)) +
                                  parseInt(
                                    getInfo(player.player, "legWon", 2)
                                  ) +
                                  parseInt(getInfo(player.player, "legWon", 3))}
                                &nbsp;-&nbsp;
                                {parseInt(
                                  getInfo(player.player, "legLost", 1)
                                ) +
                                  parseInt(
                                    getInfo(player.player, "legLost", 2)
                                  ) +
                                  parseInt(
                                    getInfo(player.player, "legLost", 3)
                                  )}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#97574d",
                                  color: "#d1d1d1",
                                }}
                              >
                                {parseInt(
                                  getInfo(player.player, "legDiff", 1)
                                ) +
                                  parseInt(
                                    getInfo(player.player, "legDiff", 2)
                                  ) +
                                  parseInt(
                                    getInfo(player.player, "legDiff", 3)
                                  )}
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#97574d",
                                  color: "#d1d1d1",
                                }}
                              >
                                {getPlacement(player.player)}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

const EndGameBracket = ({ tourID, isAdmin }) => {
  const [endGames, setEndGames] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");
  const [p1WinnerChecked, setP1WinnerChecked] = useState(false);
  const [p2WinnerChecked, setP2WinnerChecked] = useState(false);

  useEffect(() => {
    getMatches();

    const int = setInterval(getMatches, 25000);

    return () => {
      clearInterval(int);
    };
  }, []); // Tomt avhengighetsarray betyr at denne effekten kun kjøres ved montering og avmontering

  const getMatches = async () => {
    try {
      const res = await fetch(
        `https://tromsodartklubb.no/api/getEndGameMatches.php?id=${tourID}`
      );
      const data = await res.json();
      setEndGames(data);
    } catch (err) {
      return null;
    }
  };

  const publishResult = async () => {
    try {
      let winner;

      if (p1WinnerChecked) {
        winner = p1;
      }

      if (p2WinnerChecked) {
        winner = p2;
      }

      const dataToSend = {
        tID: tourID,
        p1: p1,
        p2: p2,
        winner: winner,
        // Legg til flere variabler her etter behov
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Endre content type avhengig av serverens krav
          // Legg til andre nødvendige headers her
        },
        body: JSON.stringify(dataToSend),
      };

      const res = await fetch(
        "https://tromsodartklubb.no/api/setEndResult.php",
        requestOptions
      );
      const data = await res.json();

      getMatches();
    } catch (err) {
      console.log(err);
    }
  };

  const matches = [
    {
      id: "8-1",
      nextMatchId: "4-1", // Id for the nextMatch in the bracket, if it's final match it must be null OR undefined
      //tournamentRoundText: "4", // Text for Round Header
      startTime: "2024-01-31 19:22:28",
      state: "SCHEDULED", // 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | 'DONE' | 'SCORE_DONE' Only needed to decide walkovers and if teamNames are TBD (to be decided)
      participants: [
        {
          id: 123, // Unique identifier of any kind
          //resultText: null, // Any string works
          isWinner: false,
          //status: null, // 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | null
          name: "hehe",
        },
        {
          id: 125, // Unique identifier of any kind
          //resultText: null, // Any string works
          isWinner: false,
          //status: null, // 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | null
          name: "haha",
        },
      ],
    },
    {
      id: "8-2",
      nextMatchId: "4-1", // Id for the nextMatch in the bracket, if it's final match it must be null OR undefined
      //tournamentRoundText: "4", // Text for Round Header
      startTime: "2024-01-31 19:22:28",
      state: "SCHEDULED", // 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | 'DONE' | 'SCORE_DONE' Only needed to decide walkovers and if teamNames are TBD (to be decided)
      participants: [
        {
          id: 123, // Unique identifier of any kind
          //resultText: null, // Any string works
          isWinner: false,
          //status: null, // 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | null
          name: "hehe",
        },
        {
          id: 125, // Unique identifier of any kind
          //resultText: null, // Any string works
          isWinner: false,
          //status: null, // 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | null
          name: "haha",
        },
      ],
    },
    {
      id: "4-1",
      nextMatchId: null, // Id for the nextMatch in the bracket, if it's final match it must be null OR undefined
      //tournamentRoundText: "4", // Text for Round Header
      startTime: "2024-01-31 19:22:28",
      state: "SCHEDULED", // 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | 'DONE' | 'SCORE_DONE' Only needed to decide walkovers and if teamNames are TBD (to be decided)
      participants: [
        {
          id: 123, // Unique identifier of any kind
          //resultText: null, // Any string works
          isWinner: false,
          //status: null, // 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | null
          name: "hehe",
        },
        {
          id: 125, // Unique identifier of any kind
          //resultText: null, // Any string works
          isWinner: false,
          //status: null, // 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | null
          name: "haha",
        },
      ],
    },
  ];

  const singleElimination = {
    teams: [
      // Matchups
      ["Team 1", "Team 2"], // First match
      ["Team 3", "Team 4"], // Second match
    ],
    results: [
      // List of brackets (single elimination, so only one bracket)
      [
        // List of rounds in bracket
        [
          // First round in this bracket
          [1, 2], // Team 1 vs Team 2
          [3, 4], // Team 3 vs Team 4
        ],
        [
          // Second (final) round in single elimination bracket
          [5, 6], // Match for first place
          [7, 8], // Match for 3rd place
        ],
      ],
    ],
  };

  const TDKtheme = createTheme({
    textColor: { main: "#000000", highlighted: "#07090D", dark: "#3E414D" },
    matchBackground: { wonColor: "#daebf9", lostColor: "#96c6da" },
    score: {
      background: { wonColor: "#87b2c4", lostColor: "#87b2c4", fontSize: 5 },
      text: { highlightedWonColor: "#7BF59D", highlightedLostColor: "#FB7E94" },
    },
    border: {
      color: "#CED1F2",
      highlightedColor: "#da96c6",
    },
    margin: 0,
    padding: 0,
    roundHeader: { backgroundColor: "#da96c6", fontColor: "#000" },
    connectorColor: "#CED1F2",
    connectorColorHighlight: "#da96c6",
    svgBackground: "#FAFAFA",
  });

  const openEndGameResultReg = (p1, p2) => {
    setModalShow(true);
    setP1(p1);
    setP2(p2);
  };

  return (
    <div className="container-fluid py-4">
      {endGames.length > 0 && (
        <>
          <div className="overflow-auto">
            <BracketProvider
              data={endGames}
              isAdmin={isAdmin}
              regRes={openEndGameResultReg}
            />
            <div style={{ whiteSpace: "nowrap", display: "none" }}>
              <center>
                {isAdmin ? (
                  <SingleEliminationBracket
                    matches={endGames}
                    //theme={TDKtheme}
                    onMatchClick={(m) => {
                      setModalShow(true);
                      setP1(m.match.p1);
                      setP2(m.match.p2);
                    }}
                    matchComponent={Match}
                  />
                ) : (
                  <SingleEliminationBracket
                    matches={endGames}
                    matchComponent={Match}
                    options={{
                      style: {
                        roundHeader: {
                          backgroundColor: "blue",
                          fontColor: "white",
                        },
                      },
                    }}
                  />
                )}
              </center>
            </div>
          </div>

          <BModal show={modalShow} onHide={() => setModalShow(false)}>
            <BModal.Header closeButton>
              <BModal.Title style={{ color: "lightgrey" }}>
                Registrer resultat | Sluttspill
              </BModal.Title>
            </BModal.Header>
            <BModal.Body>
              {/* Modal content goes here */}
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Spiller</th>
                    <th scope="col">Vinner</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{p1}</th>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={p1WinnerChecked}
                        onChange={() => {
                          setP2WinnerChecked(false);
                          setP1WinnerChecked(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{p2}</th>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={p2WinnerChecked}
                        onChange={() => {
                          setP2WinnerChecked(true);
                          setP1WinnerChecked(false);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </BModal.Body>
            <BModal.Footer>
              <Button variant="secondary" onClick={() => setModalShow(false)}>
                Lukk
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  publishResult();
                  setModalShow(false);
                }}
              >
                Oppdater resultat
              </Button>
            </BModal.Footer>
          </BModal>
        </>
      )}
    </div>
  );
};

function Modal({ tourID, p1, p2, roundId, updateResults }) {
  const [p1Res, setP1Res] = useState(0);
  const [p2Res, setP2Res] = useState(0);
  const [p1WinnerChecked, setP1WinnerChecked] = useState(false);
  const [p2WinnerChecked, setP2WinnerChecked] = useState(false);

  const [winner, setWinner] = useState("");

  useEffect(() => {
    if (p1WinnerChecked && !p2WinnerChecked) {
      setWinner(p1);
    }

    if (p2WinnerChecked && !p1WinnerChecked) {
      setWinner(p2);
    }
  }, [p1WinnerChecked, p2WinnerChecked]);

  useEffect(() => {
    if (p1Res > p2Res) {
      setP1WinnerChecked(true);
      setP2WinnerChecked(false);
    }
    if (p2Res > p1Res) {
      setP2WinnerChecked(true);
      setP1WinnerChecked(false);
    }

    if (p1Res < 0) {
      setP1Res(0);
    }
    /*
    if (p1Res > 2) {
      setP1Res(2);
    }
    */
    if (p2Res < 0) {
      setP2Res(0);
    }
    /*
    if (p2Res > 2) {
      setP2Res(2);
    }
    */
  }, [p1Res, p2Res]);

  async function publishResult() {
    try {
      const dataToSend = {
        tID: parseInt(tourID),
        p1: p1,
        p2: p2,
        p1Res: parseInt(p1Res),
        p2Res: parseInt(p2Res),
        roundId: parseInt(roundId),
        winner: winner,
        // Legg til flere variabler her etter behov
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Endre content type avhengig av serverens krav
          // Legg til andre nødvendige headers her
        },
        body: JSON.stringify(dataToSend),
      };

      const res = await fetch(
        "https://tromsodartklubb.no/api/setResult.php",
        requestOptions
      );
      const data = await res.json();

      console.log(data);
      setP1Res(0);
      setP2Res(0);
      setP1WinnerChecked(false);
      setP2WinnerChecked(false);
    } catch (err) {
      //alert("Noe gikk galt.. Prøv på nytt")
      console.log(err);
      setP1Res(0);
      setP2Res(0);
      setP1WinnerChecked(false);
      setP2WinnerChecked(false);
    }
  }

  return (
    <>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Registrer resultat
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Spiller</th>
                    <th scope="col">Resultat</th>
                    <th scope="col">Vinner</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{p1}</th>
                    <td>
                      <input
                        type="number"
                        value={p1Res}
                        onChange={(e) => setP1Res(e.target.value)}
                        min={0}
                        max={3}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={p1WinnerChecked}
                        onChange={() => {
                          setP2WinnerChecked(false);
                          setP1WinnerChecked(true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{p2}</th>
                    <td>
                      <input
                        type="number"
                        value={p2Res}
                        onChange={(e) => setP2Res(e.target.value)}
                        min={0}
                        max={3}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={p2WinnerChecked}
                        onChange={() => {
                          setP2WinnerChecked(true);
                          setP1WinnerChecked(false);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Lukk
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => publishResult().then(() => updateResults())}
              >
                Registrer resultat
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const tableContainerStyle = {
  overflowX: "auto",
  whiteSpace: "nowrap",
};

const fixedCellStyle = {
  position: "sticky",
  left: 0,
  zIndex: 2,
  backgroundColor: "white",
};

const tableStyle = {
  width: "100%",
};

const cellStyle = {
  minWidth: "100px",
  textAlign: "center",
  padding: "8px",
  border: "1px solid #ddd",
};
