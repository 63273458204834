import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

//import Lightbox from "yet-another-react-lightbox";
//import "yet-another-react-lightbox/styles.css";

export default function Gallery({ isLoggedIn }) {
  const params = useParams();
  const [galleries, setGalleries] = useState([]);
  const [galleryName, setGalleryName] = useState("");
  const [bilder, setBilder] = useState([]);

  useEffect(() => {
    if (params.id) {
      const wantedObject = galleries.find((obj) => obj.id === params.id);

      if (wantedObject) {
        // Objektet med ønsket id ble funnet
        setGalleryName(wantedObject.name);
        setBilder(wantedObject.bilder);

        document.title = wantedObject.name + " - Tromsø Dartklubb";
      } else {
        // Objektet med ønsket id ble ikke funnet
        console.log("Objektet ble ikke funnet");
      }
    } else {
      document.title = "Galleri - Tromsø Dartklubb";
    }
  }, [params.id, galleries]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getGalleries();

    // Update title
    document.title = "Galleri - Tromsø Dartklubb";
  }, []);

  const getGalleries = async () => {
    try {
      const res = await fetch(
        `https://tromsodartklubb.no/api/getGalleries.php`
      );
      const data = await res.json();
      setGalleries(data);
      if (params.id) {
        setBilder(data.bilder);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container">
      {params.id ? (
        <>
          <div className="row" style={{ marginTop: 5 }}>
            <div className="col-sm-10">
              <h2>{galleryName}</h2>
            </div>
            <div className="col-sm-2">
              <button type="button" className="btn btn-success d-none">
                Last opp bilder
              </button>
            </div>
          </div>

          <div
            className="row d-flex align-items-center"
            style={{ marginBottom: 15 }}
          >
            {bilder && (
              <>
                {bilder.map((bilde, key) => {
                  return (
                    <div
                      className="col-sm-6 border border-dark rounded p-1"
                      key={key}
                      style={{ padding: 0 }}
                    >
                      <a
                        href={`https://tromsodartklubb.no/images/${params.id}/${bilde}`}
                        data-lightbox={`roadtrip`}
                      >
                        <img
                          className="card-img rounded-sm"
                          style={{ width: "100%" }}
                          src={`https://tromsodartklubb.no/images/${params.id}/${bilde}`}
                        />
                      </a>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <h2>Galleri</h2>
          <div className="row" style={{ marginBottom: 15 }}>
            {galleries.map((gallery, key) => {
              return (
                <div className="col-sm-4 card" key={key} style={{ padding: 0 }}>
                  <Link to={"./" + gallery.id}>
                    <img
                      className="card-img"
                      style={{ width: "100%" }}
                      src={`https://tromsodartklubb.no/images/${gallery.id}/${gallery.bilder[0]}`}
                    />
                    <div className="card-img-overlay" style={{ padding: 0 }}>
                      <h5
                        className="card-title text-center"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          backgroundColor: "grey",
                          width: "100%",
                          padding: 15,
                          color: "#FAFAFA",
                        }}
                      >
                        {gallery.name}
                      </h5>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
