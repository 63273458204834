import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

export function News({ isLoggedIn }) {
  const params = useParams();
  const [news, setNews] = useState([]);

  // Admin
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const editorRef = useRef(null);

  useEffect(() => {
    document.title = "Nyheter - Tromsø Dartklubb";

    getNews();
  }, []);

  const getNews = async () => {
    try {
      const res = await fetch("https://tromsodartklubb.no/api/getNews.php");
      const data = await res.json();
      setNews(data);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const updateEditorContent = (content, editor) => {
    setContent(content);
  };

  const postNews = async () => {
    if (title && content) {
      try {
        const dataToSend = {
          title: title,
          content: content,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Endre content type avhengig av serverens krav
            // Legg til andre nødvendige headers her
          },
          body: JSON.stringify(dataToSend),
        };
        await fetch(
          "https://tromsodartklubb.no/api/postNews.php",
          requestOptions
        );
        editorRef.current.setDirty(false);
        window.location.reload(false);
      } catch (err) {
        alert("Noe gikk galt.. " + err.message);
      }
    } else {
      alert("Tittel eller innhold er tom. Fyll inn tittel/innhold");
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between">
        <div>
          <h2>Nyheter</h2>
        </div>
        {isLoggedIn && (
          <div>
            <>
              {/* Button trigger modal */}
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Legg til nyhet
              </button>
              {/* Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Legg til nyhet
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          Velg tittel:
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="basic-addon1"
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </div>
                      <hr />
                      <Editor
                        apiKey="yu6xnieo70931d10nfq7u7peqjlniwsm65o1mtyhigyna5ao"
                        onEditorChange={updateEditorContent}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={{
                          tinydrive_token_provider:
                            //"https://intern.tkva.no/_functions/_jwt/jwt.php",
                            "https://tromsodartklubb.no/api/tinydrive.php",
                          min_height: 800,
                          plugins: [
                            "autoresize",
                            "code",
                            "tinydrive",
                            "fullscreen",
                            "image",
                            "emoticons",
                            "lists",
                            "link",
                            "media",
                            "table",
                            "anchor",
                            "autosave",
                            "accordion",
                            //"importcss",
                            "preview",
                            "quickbars",
                            "visualblocks",
                            "toc",
                          ],
                          toolbar1:
                            "undo redo | indentation | styles | anchor | bsTemplate tkvaSource |  preview | lagre",
                          toolbar2:
                            "bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link table image media | emoticons forecolor backcolor | insertfile code | fullscreen",
                        }}
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Lukk
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                        onClick={() => postNews()}
                      >
                        Publiser
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        )}
      </div>

      {news.length > 0 ? (
        <div className="row">
          {news.map((tour, k) => {
            return (
              <div key={k} className="col-sm-4">
                <Link to={`${tour.id}`}>
                  <div
                    className="d-flex flex-column  justify-content-end m-2 p-2 shadow-lg rounded"
                    style={{
                      backgroundImage: `url(${tour.img})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      minHeight: "320px",
                    }}
                  >
                    <h5
                      style={{
                        color: "#192a33",
                        fontWeight: "600",
                        letterSpacing: 3,
                        backgroundColor: "rgba(255,255,255,0.5)",
                        width: "100%",
                        padding: 5,
                      }}
                    >
                      {tour.title}
                    </h5>
                    <div
                      className="row m-0"
                      style={{
                        color: "#FAFAFA",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <div className="col">
                        <i
                          className="bi bi-calendar3"
                          style={{ color: "#3498db" }}
                        />{" "}
                        {tour.created}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      ) : (
        <h5>Ingen nyheter</h5>
      )}
    </div>
  );
}

export function NewsArticle({ isLoggedIn }) {
  const params = useParams();
  const [article, setArticle] = useState([]);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const editorRef = useRef(null);

  useEffect(() => {
    getArticle();
  }, []);

  const updateEditorContent = (content, editor) => {
    setContent(content);
  };

  const getArticle = async () => {
    try {
      const res = await fetch(
        `https://tromsodartklubb.no/api/getNews.php?id=${params.id}`
      );
      const data = await res.json();
      setArticle(data);
      setTitle(data[0].title);
      setContent(data[0].text);
      document.title = data[0].title + " - Tromsø Dartklubb";
    } catch (err) {
      return;
    }
  };

  const updateArticle = async () => {
    if (title && content) {
      try {
        const dataToSend = {
          id: params.id,
          title: title,
          content: content,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Endre content type avhengig av serverens krav
            // Legg til andre nødvendige headers her
          },
          body: JSON.stringify(dataToSend),
        };
        await fetch(
          "https://tromsodartklubb.no/api/editNews.php",
          requestOptions
        );
        editorRef.current.setDirty(false);
        window.location.reload(false);
      } catch (err) {
        alert("Noe gikk galt.. " + err.message);
      }
    } else {
      alert("Tittel eller innhold er tom. Fyll inn tittel/innhold");
    }
  };

  return (
    <div className="container">
      {article.length > 0 ? (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <h2>{article[0].title}</h2>
            </div>
            {isLoggedIn && (
              <div>
                <>
                  {/* Button trigger modal */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Endre artikkel
                  </button>
                  {/* Modal */}
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Oppdater nyhet
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body">
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              Velg tittel:
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={article[0].title}
                              aria-describedby="basic-addon1"
                              onChange={(e) => setTitle(e.target.value)}
                              required
                            />
                          </div>
                          <hr />
                          <Editor
                            apiKey="yu6xnieo70931d10nfq7u7peqjlniwsm65o1mtyhigyna5ao"
                            onEditorChange={updateEditorContent}
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            init={{
                              init_instance_callback: (inst) => {
                                inst.setContent(article[0].text);
                              },
                              tinydrive_token_provider:
                                //"https://intern.tkva.no/_functions/_jwt/jwt.php",
                                "https://tromsodartklubb.no/api/tinydrive.php",
                              min_height: 800,
                              plugins: [
                                "autoresize",
                                "code",
                                "tinydrive",
                                "fullscreen",
                                "image",
                                "emoticons",
                                "lists",
                                "link",
                                "media",
                                "table",
                                "anchor",
                                "autosave",
                                "accordion",
                                //"importcss",
                                "preview",
                                "quickbars",
                                "visualblocks",
                                "toc",
                              ],
                              toolbar1:
                                "undo redo | indentation | styles | anchor | bsTemplate tkvaSource |  preview | lagre",
                              toolbar2:
                                "bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link table image media | emoticons forecolor backcolor | insertfile code | fullscreen",
                            }}
                          />
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Lukk
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                            onClick={() => updateArticle()}
                          >
                            Oppdater
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            )}
          </div>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: article[0].text }} />
          <hr />
          <span className="muted">Publisert {article[0].created}</span>
        </>
      ) : (
        <h4>Ingen artikkel funnet</h4>
      )}
    </div>
  );
}
